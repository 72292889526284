"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isLength = void 0;
/**
 * @ignore
 * @private
 * @internal
 */
const MAX_SAFE_INTEGER = Number.MAX_SAFE_INTEGER || 9007199254740991;
/**
 * Checks if `value` is a valid array-like length.
 *
 * **Note:** This method is loosely based on
 * [`ToLength`](http://ecma-international.org/ecma-262/7.0/#sec-tolength).
 *
 * @since 5.5.0
 * @category Lang
 * @param value The value to check.
 * @returns Returns `true` if `value` is a valid length, else `false`.
 * @example
 *
 * ```js
 * isLength(3)
 * // => true
 *
 * isLength(Number.MIN_VALUE)
 * // => false
 *
 * isLength(Infinity)
 * // => false
 *
 * isLength('3')
 * // => false
 * ```
 */
function isLength(value) {
  return typeof value === "number" && value > -1 && value % 1 == 0 && value <= MAX_SAFE_INTEGER;
}
exports.isLength = isLength;
exports.default = isLength;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const GLOBAL_1 = require("./GLOBAL");
/**
 * Converts `func` to its source code.
 *
 * @private
 * @param {Function} func The function to convert.
 * @returns {string} Returns the source code.
 */
function toSource(func) {
  if (func != null) {
    try {
      return GLOBAL_1.funcToString.call(func);
    } catch (e) {}
    try {
      return func + '';
    } catch (e) {}
  }
  return '';
}
exports.default = toSource;
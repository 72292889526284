"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.HALF_MAX_ARRAY_LENGTH = exports.MAX_ARRAY_INDEX = exports.MAX_ARRAY_LENGTH = exports.NAN = exports.MAX_INTEGER = exports.MAX_SAFE_INTEGER = exports.INFINITY = exports.LAZY_WHILE_FLAG = exports.LAZY_MAP_FLAG = exports.LAZY_FILTER_FLAG = exports.HOT_SPAN = exports.HOT_COUNT = exports.DEFAULT_TRUNC_OMISSION = exports.DEFAULT_TRUNC_LENGTH = exports.WRAP_FLIP_FLAG = exports.WRAP_REARG_FLAG = exports.WRAP_ARY_FLAG = exports.WRAP_PARTIAL_RIGHT_FLAG = exports.WRAP_PARTIAL_FLAG = exports.WRAP_CURRY_RIGHT_FLAG = exports.WRAP_CURRY_FLAG = exports.WRAP_CURRY_BOUND_FLAG = exports.WRAP_BIND_KEY_FLAG = exports.WRAP_BIND_FLAG = exports.COMPARE_UNORDERED_FLAG = exports.COMPARE_PARTIAL_FLAG = exports.CLONE_SYMBOLS_FLAG = exports.CLONE_FLAT_FLAG = exports.CLONE_DEEP_FLAG = exports.PLACEHOLDER = exports.MAX_MEMOIZE_SIZE = exports.HASH_UNDEFINED = exports.FUNC_ERROR_TEXT = exports.CORE_ERROR_TEXT = exports.LARGE_ARRAY_SIZE = void 0;
/** Used as the size to enable large array optimizations. */
exports.LARGE_ARRAY_SIZE = 200;
/** Error message constants. */
exports.CORE_ERROR_TEXT = 'Unsupported core-js use. Try https://npms.io/search?q=ponyfill.';
exports.FUNC_ERROR_TEXT = 'Expected a function';
/** Used to stand-in for `undefined` hash values. */
exports.HASH_UNDEFINED = '__lodash_hash_undefined__';
/** Used as the maximum memoize cache size. */
exports.MAX_MEMOIZE_SIZE = 500;
/** Used as the internal argument placeholder. */
exports.PLACEHOLDER = '__lodash_placeholder__';
/** Used to compose bitmasks for cloning. */
exports.CLONE_DEEP_FLAG = 1;
exports.CLONE_FLAT_FLAG = 2;
exports.CLONE_SYMBOLS_FLAG = 4;
/** Used to compose bitmasks for value comparisons. */
exports.COMPARE_PARTIAL_FLAG = 1;
exports.COMPARE_UNORDERED_FLAG = 2;
/** Used to compose bitmasks for function metadata. */
exports.WRAP_BIND_FLAG = 1;
exports.WRAP_BIND_KEY_FLAG = 2;
exports.WRAP_CURRY_BOUND_FLAG = 4;
exports.WRAP_CURRY_FLAG = 8;
exports.WRAP_CURRY_RIGHT_FLAG = 16;
exports.WRAP_PARTIAL_FLAG = 32;
exports.WRAP_PARTIAL_RIGHT_FLAG = 64;
exports.WRAP_ARY_FLAG = 128;
exports.WRAP_REARG_FLAG = 256;
exports.WRAP_FLIP_FLAG = 512;
/** Used as default options for `truncate`. */
exports.DEFAULT_TRUNC_LENGTH = 30;
exports.DEFAULT_TRUNC_OMISSION = '...';
/** Used to detect hot functions by number of calls within a span of milliseconds. */
exports.HOT_COUNT = 800;
exports.HOT_SPAN = 16;
/** Used to indicate the type of lazy iteratees. */
exports.LAZY_FILTER_FLAG = 1;
exports.LAZY_MAP_FLAG = 2;
exports.LAZY_WHILE_FLAG = 3;
/** Used as references for various `Number` constants. */
exports.INFINITY = 1 / 0;
exports.MAX_SAFE_INTEGER = 9007199254740991;
exports.MAX_INTEGER = 1.7976931348623157e+308;
exports.NAN = 0 / 0;
/** Used as references for the maximum length and index of an array. */
exports.MAX_ARRAY_LENGTH = 4294967295;
exports.MAX_ARRAY_INDEX = exports.MAX_ARRAY_LENGTH - 1;
exports.HALF_MAX_ARRAY_LENGTH = exports.MAX_ARRAY_LENGTH >>> 1;
"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __exportStar = this && this.__exportStar || function (m, exports) {
  for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.literal = exports.keys = exports.filter = exports.query = exports.resourcePath = exports.odataUri = exports.Parser = exports.parserFactory = void 0;
const Expressions = require("./expressions");
const ArrayOrObject = require("./json");
const ODataUri = require("./odataUri");
const PrimitiveLiteral = require("./primitiveLiteral");
const Query = require("./query");
const ResourcePath = require("./resourcePath");
const parserFactory = function (fn) {
  return function (source, options) {
    options = options || {};
    const raw = new Uint16Array(source.length);
    const pos = 0;
    for (let i = 0; i < source.length; i++) {
      raw[i] = source.charCodeAt(i);
    }
    const result = fn(raw, pos, options.metadata);
    if (!result) {
      throw new Error(`Fail at ${pos}`);
    }
    if (result.next < raw.length) {
      throw new Error(`Unexpected character at ${result.next}`);
    }
    return result;
  };
};
exports.parserFactory = parserFactory;
/**
 * odata uri parser
 */
class Parser {
  /**
   * parser ast node with full odata uri
   *
   * @param source
   * @param options
   */
  odataUri(source, options) {
    return (0, exports.parserFactory)(ODataUri.odataUri)(source, options);
  }
  resourcePath(source, options) {
    return (0, exports.parserFactory)(ResourcePath.resourcePath)(source, options);
  }
  query(source, options) {
    return (0, exports.parserFactory)(Query.queryOptions)(source, options);
  }
  filter(source, options) {
    return (0, exports.parserFactory)(Expressions.boolCommonExpr)(source, options);
  }
  keys(source, options) {
    return (0, exports.parserFactory)(Expressions.keyPredicate)(source, options);
  }
  literal(source, options) {
    return (0, exports.parserFactory)(PrimitiveLiteral.primitiveLiteral)(source, options);
  }
  arrayOrObject(source, index) {
    return (0, exports.parserFactory)(ArrayOrObject.arrayOrObject)(source, index);
  }
}
exports.Parser = Parser;
function odataUri(source, options) {
  return (0, exports.parserFactory)(ODataUri.odataUri)(source, options);
}
exports.odataUri = odataUri;
function resourcePath(source, options) {
  return (0, exports.parserFactory)(ResourcePath.resourcePath)(source, options);
}
exports.resourcePath = resourcePath;
function query(source, options) {
  return (0, exports.parserFactory)(Query.queryOptions)(source, options);
}
exports.query = query;
function filter(source, options) {
  return (0, exports.parserFactory)(Expressions.boolCommonExpr)(source, options);
}
exports.filter = filter;
function keys(source, options) {
  return (0, exports.parserFactory)(Expressions.keyPredicate)(source, options);
}
exports.keys = keys;
function literal(source, options) {
  return (0, exports.parserFactory)(PrimitiveLiteral.primitiveLiteral)(source, options);
}
exports.literal = literal;
__exportStar(require("./types"), exports);

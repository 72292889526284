"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ODataFilter = exports.ExprOperator = void 0;
const join_1 = require("@newdash/newdash/join");
const metadata_1 = require("@odata/metadata");
const types_1 = require("./types");
var ExprOperator;
(function (ExprOperator) {
  ExprOperator["eq"] = "eq";
  ExprOperator["ne"] = "ne";
  ExprOperator["gt"] = "gt";
  ExprOperator["lt"] = "lt";
  ExprOperator["ge"] = "ge";
  ExprOperator["le"] = "le";
})(ExprOperator = exports.ExprOperator || (exports.ExprOperator = {}));
/**
 * @private
 * @internal
 */
class ODataFieldExpr {
  constructor(filter, fieldName, mapping) {
    this._exprMappings = mapping;
    this._fieldName = fieldName;
    this._filter = filter;
    // initialize
    if (this._getFieldExprs() == undefined) {
      this._exprMappings[this._fieldName] = [];
    }
  }
  _getFieldExprs() {
    return this._exprMappings[this._fieldName];
  }
  _addExpr(op, value) {
    if (value === null) {
      this._getFieldExprs().push({
        op,
        value: 'null'
      });
      return;
    }
    switch (typeof value) {
      case 'number':
      case 'boolean':
      case 'string':
      case 'object':
        this._getFieldExprs().push({
          op,
          value
        });
        break;
      case 'undefined':
        throw new Error(`You must set value in odata filter eq/ne/gt/ge/ne/nt ...`);
      default:
        throw new Error(`Not support typeof ${typeof value}: ${value} in odata filter eq/ne/gt/ge/ne/nt ...`);
    }
  }
  /**
   * equal
   * @param value
   */
  eq(value) {
    this._addExpr(ExprOperator.eq, value);
    return this._filter;
  }
  /**
   * not equal
   * @param value
   */
  ne(value) {
    this._addExpr(ExprOperator.ne, value);
    return this._filter;
  }
  eqString(value) {
    this._addExpr(ExprOperator.eq, `'${value}'`);
    return this._filter;
  }
  neString(value) {
    this._addExpr(ExprOperator.ne, `'${value}'`);
    return this._filter;
  }
  /**
   * greater or equal
   * @param value
   */
  ge(value) {
    this._addExpr(ExprOperator.ge, value);
    return this._filter;
  }
  /**
   * greater than
   * @param value
   */
  gt(value) {
    this._addExpr(ExprOperator.gt, value);
    return this._filter;
  }
  /**
   * less or equal
   * @param value
   */
  le(value) {
    this._addExpr(ExprOperator.le, value);
    return this._filter;
  }
  /**
   * less than
   * @param value
   */
  lt(value) {
    this._addExpr(ExprOperator.lt, value);
    return this._filter;
  }
  /**
   * match any value in an array
   *
   * @param values
   */
  in(values = []) {
    if (values.length > 0) {
      values.forEach(value => {
        this.eq(value);
      });
    }
    return this._filter;
  }
  /**
   * filter by value range
   *
   * @param low
   * @param max
   * @param includeBoundary
   */
  between(low, max, includeBoundary = true) {
    if (low == undefined || max == undefined) {
      throw new Error('You must give out the start and end value');
    }
    if (includeBoundary) {
      this.ge(low);
      this.le(max);
    } else {
      this.gt(low);
      this.lt(max);
    }
    return this._filter;
  }
}
/**
 * OData filter builder
 */
class ODataFilter {
  static New(obj) {
    return new ODataFilter(obj);
  }
  constructor(obj) {
    this._fieldExprMappings = {};
    if (obj != undefined && typeof obj == 'object') {
      Object.entries(obj).forEach(([prop, value]) => {
        this.field(prop).eq(value);
      });
    }
  }
  /**
   * getExprMapping
   *
   * @internal
   * @private
   */
  getExprMapping() {
    return this._fieldExprMappings;
  }
  /**
   * @param name filed name
   */
  field(name) {
    return new ODataFieldExpr(this, name, this.getExprMapping());
  }
  toString(version = 'v4') {
    return this.build(version);
  }
  _buildExprLit(value, version = 'v4') {
    var _a;
    if (value === null) {
      return 'null';
    }
    switch (typeof value) {
      case 'number':
      case 'boolean':
        return `${value}`;
      case 'string':
        if (value.startsWith("'") || value.startsWith('datetime')) {
          return value;
        }
        return `'${value}'`;
      case 'object':
        if (value instanceof metadata_1.Edm.PrimitiveTypeValue) {
          return (0, types_1.convertPrimitiveValueToString)(value, version);
        }
        throw new Error(`Not support object ${((_a = value === null || value === void 0 ? void 0 : value.constructor) === null || _a === void 0 ? void 0 : _a.name) || typeof value} in odata filter eq/ne/gt/ge/ne/nt ...`);
      case 'undefined':
        throw new Error(`You must set value in odata filter eq/ne/gt/ge/ne/nt ...`);
      default:
        throw new Error(`Not support typeof ${typeof value}: ${value} in odata filter eq/ne/gt/ge/ne/nt ...`);
    }
  }
  _buildFieldExprString(field, version = 'v4') {
    const exprs = this.getExprMapping()[field];
    if (exprs.length > 0) {
      if (exprs.filter(expr => expr.op == ExprOperator.eq).length == 0) {
        return `(${(0, join_1.default)(exprs.map(({
          op,
          value
        }) => `${field} ${op} ${this._buildExprLit(value, version)}`), ' and ')})`;
      }
      return `(${(0, join_1.default)(exprs.map(({
        op,
        value
      }) => `${field} ${op} ${this._buildExprLit(value, version)}`), ' or ')})`;
    }
    return '';
  }
  build(version = 'v4') {
    let _rt = '';
    _rt = (0, join_1.default)(
    // join all fields exprs string
    Object.entries(this.getExprMapping()).map(([fieldName, exprs]) => {
      switch (exprs.length) {
        // if one field expr mapping array is empty
        case 0:
          return '';
        // only have one expr
        case 1:
          const {
            op,
            value
          } = exprs[0];
          return `${fieldName} ${op} ${this._buildExprLit(value, version)}`;
        default:
          // multi exprs
          return this._buildFieldExprString(fieldName, version);
      }
    }), ' and ');
    return _rt;
  }
}
exports.ODataFilter = ODataFilter;

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mustProvide = void 0;
/**
 * must provide value with type
 *
 * @param value
 * @param fieldName
 * @param type
 */
function mustProvide(value, fieldName, type) {
  if (value === undefined || value === null || typeof value !== type) {
    throw new TypeError(`must provide '${fieldName}' value with type '${type}', given: '${value}'/'${type}'`);
  }
}
exports.mustProvide = mustProvide;
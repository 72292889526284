"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.identity = void 0;
/**
 * This method returns the first argument it receives.
 *
 * @ignore
 * @private
 * @since 5.10.0
 * @category Util
 * @param value Any value.
 * @returns Returns `value`.
 * @example
 *
 * ```js
 * var object = { 'a': 1 };
 *
 * console.log(identity(object) === object);
 * // => true
 * ```
 */
function identity(value) {
  return value;
}
exports.identity = identity;
exports.default = identity;
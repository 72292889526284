"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __exportStar = this && this.__exportStar || function (m, exports) {
  for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.literalValues = exports.filter = exports.param = void 0;
const metadata_1 = require("@odata/metadata");
const filter_1 = require("./filter");
const param_1 = require("./param");
__exportStar(require("./batch"), exports);
__exportStar(require("./filter"), exports);
__exportStar(require("./param"), exports);
__exportStar(require("./types"), exports);
function param() {
  return param_1.ODataParam.New();
}
exports.param = param;
function filter(obj) {
  return filter_1.ODataFilter.New(obj);
}
exports.filter = filter;
/**
 * edm primitive literal value creators
 */
exports.literalValues = {
  Binary: value => metadata_1.Edm.Binary.createValue(value),
  Boolean: value => metadata_1.Edm.Boolean.createValue(value),
  Byte: value => metadata_1.Edm.Byte.createValue(value),
  Date: value => metadata_1.Edm.Date.createValue(value),
  DateTimeOffset: value => metadata_1.Edm.DateTimeOffset.createValue(value),
  Decimal: value => metadata_1.Edm.Decimal.createValue(value),
  Double: value => metadata_1.Edm.Double.createValue(value),
  Duration: value => metadata_1.Edm.Duration.createValue(value),
  Guid: value => metadata_1.Edm.Guid.createValue(value),
  Int16: value => metadata_1.Edm.Int16.createValue(value),
  Int32: value => metadata_1.Edm.Int32.createValue(value),
  Int64: value => metadata_1.Edm.Int64.createValue(value),
  SByte: value => metadata_1.Edm.SByte.createValue(value),
  Single: value => metadata_1.Edm.Single.createValue(value),
  Stream: value => metadata_1.Edm.Stream.createValue(value),
  String: value => metadata_1.Edm.String.createValue(value),
  TimeOfDay: value => metadata_1.Edm.TimeOfDay.createValue(value),
  Geography: value => metadata_1.Edm.Geography.createValue(value),
  GeographyPoint: value => metadata_1.Edm.GeographyPoint.createValue(value),
  GeographyLineString: value => metadata_1.Edm.GeographyLineString.createValue(value),
  GeographyPolygon: value => metadata_1.Edm.GeographyPolygon.createValue(value),
  GeographyMultiPoint: value => metadata_1.Edm.GeographyMultiPoint.createValue(value),
  GeographyMultiLineString: value => metadata_1.Edm.GeographyMultiLineString.createValue(value),
  GeographyMultiPolygon: value => metadata_1.Edm.GeographyMultiPolygon.createValue(value),
  GeographyCollection: value => metadata_1.Edm.GeographyCollection.createValue(value),
  Geometry: value => metadata_1.Edm.Geometry.createValue(value),
  GeometryPoint: value => metadata_1.Edm.GeometryPoint.createValue(value),
  GeometryLineString: value => metadata_1.Edm.GeometryLineString.createValue(value),
  GeometryPolygon: value => metadata_1.Edm.GeometryPolygon.createValue(value),
  GeometryMultiPoint: value => metadata_1.Edm.GeometryMultiPoint.createValue(value),
  GeometryMultiLineString: value => metadata_1.Edm.GeometryMultiLineString.createValue(value),
  GeometryMultiPolygon: value => metadata_1.Edm.GeometryMultiPolygon.createValue(value),
  GeometryCollection: value => metadata_1.Edm.GeometryCollection.createValue(value)
};

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.convertPrimitiveValueToString = void 0;
const metadata_1 = require("@odata/metadata");
/**
 *
 * @param value primitive literal value
 * @returns the string representation
 */
function convertPrimitiveValueToString(value, version = 'v4') {
  var _a, _b, _c;
  if (((_a = value === null || value === void 0 ? void 0 : value.getValue) === null || _a === void 0 ? void 0 : _a.call(value)) === null) {
    return 'null';
  }
  if (((_b = value === null || value === void 0 ? void 0 : value.getValue) === null || _b === void 0 ? void 0 : _b.call(value)) !== undefined) {
    switch ((_c = value === null || value === void 0 ? void 0 : value.getType) === null || _c === void 0 ? void 0 : _c.call(value)) {
      case metadata_1.Edm.Int16:
      case metadata_1.Edm.Int32:
      case metadata_1.Edm.Int64:
      case metadata_1.Edm.Guid:
      case metadata_1.Edm.Double:
      case metadata_1.Edm.Decimal:
      case metadata_1.Edm.Byte:
      case metadata_1.Edm.SByte:
      case metadata_1.Edm.Single:
        return String(value.getValue());
      case metadata_1.Edm.Boolean:
        return String(value.getValue());
      case metadata_1.Edm.Binary:
        const vB = value.getValue();
        if (vB instanceof Buffer) {
          return `binary'${vB.toString('base64')}'`;
        }
        return String(vB);
      case metadata_1.Edm.String:
        return `'${value.getValue()}'`;
      case metadata_1.Edm.Duration:
        // TODO integrate with some other duration lib
        return value.getValue();
      case metadata_1.Edm.DateTime:
        let vd = value.getValue();
        if (typeof vd === 'string') {
          vd = new Date(vd);
        }
        if (version === 'v2') {
          return `datetime'${vd.toISOString()}'`;
        }
        throw new Error("OData V4 is not support 'Edm.DateTime' values");
      case metadata_1.Edm.DateTimeOffset:
        let v1 = value.getValue();
        if (typeof v1 === 'string') {
          v1 = new Date(v1);
        }
        if (version === 'v2') {
          return `datetimeoffset'${v1.toISOString()}'`;
        }
        return v1.toISOString();
      case metadata_1.Edm.Date:
        const v2 = value.getValue();
        if (v2 instanceof Date) {
          return `${v2.getFullYear()}-${v2.getMonth() + 1}-${v2.getDate()}`;
        }
        return v2;
      case metadata_1.Edm.Geography:
      case metadata_1.Edm.GeographyPoint:
      case metadata_1.Edm.GeographyLineString:
      case metadata_1.Edm.GeographyPolygon:
      case metadata_1.Edm.GeographyMultiPoint:
      case metadata_1.Edm.GeographyMultiLineString:
      case metadata_1.Edm.GeographyMultiPolygon:
      case metadata_1.Edm.GeographyCollection:
      case metadata_1.Edm.Geometry:
      case metadata_1.Edm.GeometryPoint:
      case metadata_1.Edm.GeometryLineString:
      case metadata_1.Edm.GeometryPolygon:
      case metadata_1.Edm.GeometryMultiPoint:
      case metadata_1.Edm.GeometryMultiLineString:
      case metadata_1.Edm.GeometryMultiPolygon:
      case metadata_1.Edm.GeometryCollection:
        return String(value.getValue());
      default:
        throw new TypeError(`not support type '${value.getType()}'`);
    }
  }
  throw new Error("'undefined' value provided");
}
exports.convertPrimitiveValueToString = convertPrimitiveValueToString;

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
/** Used to match `RegExp` flags from their coerced string values. */
const reFlags = /\w*$/;
/**
 * Creates a clone of `regexp`.
 *
 * @private
 * @param {Object} regexp The regexp to clone.
 * @returns {Object} Returns the cloned regexp.
 */
function cloneRegExp(regexp) {
  const result = new regexp.constructor(regexp.source, reFlags.exec(regexp));
  result.lastIndex = regexp.lastIndex;
  return result;
}
exports.default = cloneRegExp;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isUndefined = void 0;
/**
 * Checks if `value` is `undefined`.
 *
 * @since 5.6.0
 * @category Lang
 * @param value The value to check.
 * @returns Returns `true` if `value` is `undefined`, else `false`.
 * @example
 *
 * ```js
 * isUndefined(void 0)
 * // => true
 *
 * isUndefined(null)
 * // => false
 * ```
 */
function isUndefined(value) {
  return value === undefined;
}
exports.isUndefined = isUndefined;
exports.default = isUndefined;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.arrayOrObject = exports.nullInJSON = exports.booleanInJSON = exports.numberInJSON = exports.charInJSON = exports.stringInJSON = exports.primitiveLiteralInJSON = exports.rootExprCol = exports.collectionNavPropInJSON = exports.singleNavPropInJSON = exports.navigationPropertyInUri = exports.primitivePropertyInUri = exports.keyValuePairInUri = exports.annotationInUri = exports.complexPropertyInUri = exports.primitiveColInUri = exports.collectionPropertyInUri = exports.complexInUri = exports.complexColInUri = void 0;
const Expressions = require("./expressions");
const Lexer = require("./lexer");
const NameOrIdentifier = require("./nameOrIdentifier");
const PrimitiveLiteral = require("./primitiveLiteral");
const utils_1 = require("./utils");
function complexColInUri(value, index) {
  const begin = Lexer.beginArray(value, index);
  if (begin === index) {
    return;
  }
  const start = index;
  index = begin;
  const items = [];
  let token = complexInUri(value, index);
  if (token) {
    while (token) {
      items.push(token);
      index = token.next;
      const end = Lexer.endArray(value, index);
      if (end > index) {
        index = end;
        break;
      } else {
        const separator = Lexer.valueSeparator(value, index);
        if (separator === index) {
          return;
        }
        index = separator;
        token = complexInUri(value, index);
        if (!token) {
          return;
        }
      }
    }
  } else {
    const end = Lexer.endArray(value, index);
    if (end === index) {
      return;
    }
    index = end;
  }
  return Lexer.tokenize(value, start, index, {
    items
  }, Lexer.TokenType.Array);
}
exports.complexColInUri = complexColInUri;
function complexInUri(value, index) {
  const begin = Lexer.beginObject(value, index);
  if (begin === index) {
    return;
  }
  const start = index;
  index = begin;
  const items = [];
  let token = annotationInUri(value, index) || primitivePropertyInUri(value, index) || complexPropertyInUri(value, index) || collectionPropertyInUri(value, index) || navigationPropertyInUri(value, index);
  if (token) {
    while (token) {
      items.push(token);
      index = token.next;
      const end = Lexer.endObject(value, index);
      if (end > index) {
        index = end;
        break;
      } else {
        const separator = Lexer.valueSeparator(value, index);
        if (separator === index) {
          return;
        }
        index = separator;
        token = annotationInUri(value, index) || primitivePropertyInUri(value, index) || complexPropertyInUri(value, index) || collectionPropertyInUri(value, index) || navigationPropertyInUri(value, index);
        if (!token) {
          return;
        }
      }
    }
  } else {
    const end = Lexer.endObject(value, index);
    if (end === index) {
      return;
    }
    index = end;
  }
  return Lexer.tokenize(value, start, index, {
    items
  }, Lexer.TokenType.Object);
}
exports.complexInUri = complexInUri;
function collectionPropertyInUri(value, index) {
  let mark = Lexer.quotationMark(value, index);
  if (mark === index) {
    return;
  }
  const start = index;
  index = mark;
  const prop = NameOrIdentifier.primitiveColProperty(value, index) || NameOrIdentifier.complexColProperty(value, index);
  if (!prop) {
    return;
  }
  index = prop.next;
  mark = Lexer.quotationMark(value, index);
  if (mark === index) {
    return;
  }
  index = mark;
  const separator = Lexer.nameSeparator(value, index);
  if (separator === index) {
    return;
  }
  index = separator;
  const propValue = prop.type === Lexer.TokenType.PrimitiveCollectionProperty ? primitiveColInUri(value, index) : complexColInUri(value, index);
  if (!propValue) {
    return;
  }
  index = propValue.next;
  return Lexer.tokenize(value, start, index, {
    key: prop,
    value: propValue
  }, Lexer.TokenType.Property);
}
exports.collectionPropertyInUri = collectionPropertyInUri;
function primitiveColInUri(value, index) {
  const begin = Lexer.beginArray(value, index);
  if (begin === index) {
    return;
  }
  const start = index;
  index = begin;
  const items = [];
  let token = primitiveLiteralInJSON(value, index);
  if (token) {
    while (token) {
      items.push(token);
      index = token.next;
      const end = Lexer.endArray(value, index);
      if (end > index) {
        index = end;
        break;
      } else {
        const separator = Lexer.valueSeparator(value, index);
        if (separator === index) {
          return;
        }
        index = separator;
        token = primitiveLiteralInJSON(value, index);
        if (!token) {
          return;
        }
      }
    }
  } else {
    const end = Lexer.endArray(value, index);
    if (end === index) {
      return;
    }
    index = end;
  }
  return Lexer.tokenize(value, start, index, {
    items
  }, Lexer.TokenType.Array);
}
exports.primitiveColInUri = primitiveColInUri;
function complexPropertyInUri(value, index) {
  let mark = Lexer.quotationMark(value, index);
  if (mark === index) {
    return;
  }
  const start = index;
  index = mark;
  const prop = NameOrIdentifier.complexProperty(value, index);
  if (!prop) {
    return;
  }
  index = prop.next;
  mark = Lexer.quotationMark(value, index);
  if (mark === index) {
    return;
  }
  index = mark;
  const separator = Lexer.nameSeparator(value, index);
  if (separator === index) {
    return;
  }
  index = separator;
  const propValue = complexInUri(value, index);
  if (!propValue) {
    return;
  }
  index = propValue.next;
  return Lexer.tokenize(value, start, index, {
    key: prop,
    value: propValue
  }, Lexer.TokenType.Property);
}
exports.complexPropertyInUri = complexPropertyInUri;
function annotationInUri(value, index) {
  let mark = Lexer.quotationMark(value, index);
  if (mark === index) {
    return;
  }
  const start = index;
  index = mark;
  const at = Lexer.AT(value, index);
  if (!at) {
    return;
  }
  index = at;
  const namespaceNext = NameOrIdentifier.namespace(value, index);
  if (namespaceNext === index) {
    return;
  }
  const namespaceStart = index;
  index = namespaceNext;
  if (value[index] !== 0x2e) {
    return;
  }
  index++;
  const term = NameOrIdentifier.termName(value, index);
  if (!term) {
    return;
  }
  index = term.next;
  mark = Lexer.quotationMark(value, index);
  if (mark === index) {
    return;
  }
  index = mark;
  const separator = Lexer.nameSeparator(value, index);
  if (separator === index) {
    return;
  }
  index = separator;
  const token = complexInUri(value, index) || complexColInUri(value, index) || primitiveLiteralInJSON(value, index) || primitiveColInUri(value, index);
  if (!token) {
    return;
  }
  index = token.next;
  return Lexer.tokenize(value, start, index, {
    key: `@${utils_1.default.stringify(value, namespaceStart, namespaceNext)}.${term.raw}`,
    value: token
  }, Lexer.TokenType.Annotation);
}
exports.annotationInUri = annotationInUri;
function keyValuePairInUri(value, index, keyFn, valueFn) {
  let mark = Lexer.quotationMark(value, index);
  if (mark === index) {
    return;
  }
  const start = index;
  index = mark;
  const prop = keyFn(value, index);
  if (!prop) {
    return;
  }
  index = prop.next;
  mark = Lexer.quotationMark(value, index);
  if (mark === index) {
    return;
  }
  index = mark;
  const separator = Lexer.nameSeparator(value, index);
  if (separator === index) {
    return;
  }
  index = separator;
  const propValue = valueFn(value, index);
  if (!propValue) {
    return;
  }
  index = propValue.next;
  return Lexer.tokenize(value, start, index, {
    key: prop,
    value: propValue
  }, Lexer.TokenType.Property);
}
exports.keyValuePairInUri = keyValuePairInUri;
function primitivePropertyInUri(value, index) {
  return keyValuePairInUri(value, index, NameOrIdentifier.primitiveProperty, primitiveLiteralInJSON);
}
exports.primitivePropertyInUri = primitivePropertyInUri;
function navigationPropertyInUri(value, index) {
  return singleNavPropInJSON(value, index) || collectionNavPropInJSON(value, index);
}
exports.navigationPropertyInUri = navigationPropertyInUri;
function singleNavPropInJSON(value, index) {
  return keyValuePairInUri(value, index, NameOrIdentifier.entityNavigationProperty, Expressions.rootExpr);
}
exports.singleNavPropInJSON = singleNavPropInJSON;
function collectionNavPropInJSON(value, index) {
  return keyValuePairInUri(value, index, NameOrIdentifier.entityColNavigationProperty, rootExprCol);
}
exports.collectionNavPropInJSON = collectionNavPropInJSON;
function rootExprCol(value, index) {
  const begin = Lexer.beginArray(value, index);
  if (begin === index) {
    return;
  }
  const start = index;
  index = begin;
  const items = [];
  let token = Expressions.rootExpr(value, index);
  if (token) {
    while (token) {
      items.push(token);
      index = token.next;
      const end = Lexer.endArray(value, index);
      if (end > index) {
        index = end;
        break;
      } else {
        const separator = Lexer.valueSeparator(value, index);
        if (separator === index) {
          return;
        }
        index = separator;
        token = Expressions.rootExpr(value, index);
        if (!token) {
          return;
        }
      }
    }
  } else {
    const end = Lexer.endArray(value, index);
    if (end === index) {
      return;
    }
    index = end;
  }
  return Lexer.tokenize(value, start, index, {
    items
  }, Lexer.TokenType.Array);
}
exports.rootExprCol = rootExprCol;
function primitiveLiteralInJSON(value, index) {
  return stringInJSON(value, index) || numberInJSON(value, index) || booleanInJSON(value, index) || nullInJSON(value, index);
}
exports.primitiveLiteralInJSON = primitiveLiteralInJSON;
function stringInJSON(value, index) {
  let mark = Lexer.quotationMark(value, index);
  if (mark === index) {
    return;
  }
  const start = index;
  index = mark;
  let char = charInJSON(value, index);
  while (char > index) {
    index = char;
    char = charInJSON(value, index);
  }
  mark = Lexer.quotationMark(value, index);
  if (mark === index) {
    return;
  }
  index = mark;
  return Lexer.tokenize(value, start, index, 'string', Lexer.TokenType.Literal);
}
exports.stringInJSON = stringInJSON;
function charInJSON(value, index) {
  const escape = Lexer.escape(value, index);
  if (escape > index) {
    if (utils_1.default.equals(value, escape, '%2F')) {
      return escape + 3;
    }
    if (utils_1.default.equals(value, escape, '/') || utils_1.default.equals(value, escape, 'b') || utils_1.default.equals(value, escape, 'f') || utils_1.default.equals(value, escape, 'n') || utils_1.default.equals(value, escape, 'r') || utils_1.default.equals(value, escape, 't')) {
      return escape + 1;
    }
    if (utils_1.default.equals(value, escape, 'u') && utils_1.default.required(value, escape + 1, Lexer.HEXDIG, 4, 4)) {
      return escape + 5;
    }
    const escapeNext = Lexer.escape(value, escape);
    if (escapeNext > escape) {
      return escapeNext;
    }
    const mark = Lexer.quotationMark(value, escape);
    if (mark > escape) {
      return mark;
    }
  } else {
    const mark = Lexer.quotationMark(value, index);
    if (mark === index) {
      return index + 1;
    }
  }
}
exports.charInJSON = charInJSON;
function numberInJSON(value, index) {
  const token = PrimitiveLiteral.doubleValue(value, index) || PrimitiveLiteral.int64Value(value, index);
  if (token) {
    token.value = 'number';
    return token;
  }
}
exports.numberInJSON = numberInJSON;
function booleanInJSON(value, index) {
  if (utils_1.default.equals(value, index, 'true')) {
    return Lexer.tokenize(value, index, index + 4, 'boolean', Lexer.TokenType.Literal);
  }
  if (utils_1.default.equals(value, index, 'false')) {
    return Lexer.tokenize(value, index, index + 5, 'boolean', Lexer.TokenType.Literal);
  }
}
exports.booleanInJSON = booleanInJSON;
function nullInJSON(value, index) {
  if (utils_1.default.equals(value, index, 'null')) {
    return Lexer.tokenize(value, index, index + 4, 'null', Lexer.TokenType.Literal);
  }
}
exports.nullInJSON = nullInJSON;
function arrayOrObject(value, index) {
  const token = complexColInUri(value, index) || complexInUri(value, index) || rootExprCol(value, index) || primitiveColInUri(value, index);
  if (token) {
    return Lexer.tokenize(value, index, token.next, token, Lexer.TokenType.ArrayOrObject);
  }
}
exports.arrayOrObject = arrayOrObject;

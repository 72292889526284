"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ODataMethods = exports.ODataMethod = void 0;
var ODataMethod;
(function (ODataMethod) {
  ODataMethod["GET"] = "GET";
  ODataMethod["POST"] = "POST";
  ODataMethod["PATCH"] = "PATCH";
  ODataMethod["PUT"] = "PUT";
  ODataMethod["DELETE"] = "DELETE";
})(ODataMethod = exports.ODataMethod || (exports.ODataMethod = {}));
exports.ODataMethods = [ODataMethod.GET, ODataMethod.POST, ODataMethod.PATCH, ODataMethod.PUT, ODataMethod.DELETE];

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createTraverser = exports.traverseAstDeepFirst = exports.traverseAst = void 0;
const forEach_1 = require("@newdash/newdash/forEach");
const isArray_1 = require("@newdash/newdash/isArray");
const isPlainObject_1 = require("@newdash/newdash/isPlainObject");
const lexer_1 = require("./lexer");
/**
 * Traverse AST with traverser
 *
 * @param traverser
 * @param node
 */
function traverseAst(traverser, node, parent) {
  var _a, _b, _c, _d;
  if (node instanceof lexer_1.Token) {
    if ((node === null || node === void 0 ? void 0 : node.type) in traverser) {
      traverser[node === null || node === void 0 ? void 0 : node.type](node, parent);
    }
  }
  if ((0, isArray_1.isArray)(node === null || node === void 0 ? void 0 : node.value) || (0, isPlainObject_1.isPlainObject)(node === null || node === void 0 ? void 0 : node.value)) {
    (0, forEach_1.forEach)(node === null || node === void 0 ? void 0 : node.value, item => {
      if (item instanceof lexer_1.Token) {
        traverseAst(traverser, item, node);
      }
    });
  }
  if ((0, isArray_1.isArray)((_a = node === null || node === void 0 ? void 0 : node.value) === null || _a === void 0 ? void 0 : _a.options)) {
    (0, forEach_1.forEach)((_b = node === null || node === void 0 ? void 0 : node.value) === null || _b === void 0 ? void 0 : _b.options, item => {
      if (item instanceof lexer_1.Token) {
        traverseAst(traverser, item, node);
      }
    });
  }
  if ((0, isArray_1.isArray)((_c = node === null || node === void 0 ? void 0 : node.value) === null || _c === void 0 ? void 0 : _c.items)) {
    (0, forEach_1.forEach)((_d = node === null || node === void 0 ? void 0 : node.value) === null || _d === void 0 ? void 0 : _d.items, item => {
      if (item instanceof lexer_1.Token) {
        traverseAst(traverser, item, node);
      }
    });
  }
  if ((node === null || node === void 0 ? void 0 : node.value) instanceof lexer_1.Token) {
    traverseAst(traverser, node === null || node === void 0 ? void 0 : node.value, node);
  }
}
exports.traverseAst = traverseAst;
/**
 * Traverse AST with traverser (Deep First)
 *
 * @param traverser
 * @param node
 * @param parent
 */
function traverseAstDeepFirst(traverser, node, parent) {
  var _a, _b, _c, _d;
  if ((0, isArray_1.isArray)(node === null || node === void 0 ? void 0 : node.value) || (0, isPlainObject_1.isPlainObject)(node === null || node === void 0 ? void 0 : node.value)) {
    (0, forEach_1.forEach)(node === null || node === void 0 ? void 0 : node.value, item => {
      if (item instanceof lexer_1.Token) {
        traverseAstDeepFirst(traverser, item, node);
      }
    });
  }
  if ((node === null || node === void 0 ? void 0 : node.value) instanceof lexer_1.Token) {
    traverseAstDeepFirst(traverser, node === null || node === void 0 ? void 0 : node.value, node);
  }
  if ((0, isArray_1.isArray)((_a = node === null || node === void 0 ? void 0 : node.value) === null || _a === void 0 ? void 0 : _a.options)) {
    (0, forEach_1.forEach)((_b = node === null || node === void 0 ? void 0 : node.value) === null || _b === void 0 ? void 0 : _b.options, item => {
      if (item instanceof lexer_1.Token) {
        traverseAstDeepFirst(traverser, item, node);
      }
    });
  }
  if ((0, isArray_1.isArray)((_c = node === null || node === void 0 ? void 0 : node.value) === null || _c === void 0 ? void 0 : _c.items)) {
    (0, forEach_1.forEach)((_d = node === null || node === void 0 ? void 0 : node.value) === null || _d === void 0 ? void 0 : _d.items, item => {
      if (item instanceof lexer_1.Token) {
        traverseAstDeepFirst(traverser, item, node);
      }
    });
  }
  if (node instanceof lexer_1.Token) {
    if ((node === null || node === void 0 ? void 0 : node.type) in traverser) {
      traverser[node.type](node, parent);
    }
  }
}
exports.traverseAstDeepFirst = traverseAstDeepFirst;
function createTraverser(traverser, deepFirst = false) {
  return node => {
    if (deepFirst) {
      traverseAstDeepFirst(traverser, node);
    } else {
      traverseAst(traverser, node);
    }
  };
}
exports.createTraverser = createTraverser;

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ODataParam = exports.ODataQueryParam = void 0;
const concat_1 = require("@newdash/newdash/concat");
const isArray_1 = require("@newdash/newdash/isArray");
const join_1 = require("@newdash/newdash/join");
const uniq_1 = require("@newdash/newdash/uniq");
const filter_1 = require("./filter");
class SearchParams {
  constructor() {
    this._store = new Map();
  }
  append(key, value) {
    if (this._store.has(key)) {
      throw new Error(`key ${key} has been appended before, and can not be overwritten!`);
    }
    this._store.set(key, value);
  }
  toString() {
    const coll = [];
    this._store.forEach((value, key) => {
      coll.push(`${key}=${value}`);
    });
    return coll.join('&');
  }
  destroy() {
    delete this._store;
  }
}
/**
 * OData Query Param
 *
 * OData V4 support
 */
class ODataQueryParam {
  constructor() {
    this.$skip = 0;
    this.$top = 0;
    this.$select = [];
    this.$expand = [];
    this.$count = false;
  }
  static New() {
    return new ODataQueryParam();
  }
  /**
   *
   * count items in odata v4
   *
   * @param count
   *
   * @version 4.0.0
   */
  count(count = true) {
    this.$count = count;
    return this;
  }
  /**
   * apply filter for query
   *
   * @param filter
   */
  filter(filter) {
    if (filter instanceof filter_1.ODataFilter) {
      this.$filter = filter.build();
      return this;
    } else if (typeof filter === 'object') {
      this.$filter = filter_1.ODataFilter.New(filter).build();
      return this;
    } else if (typeof filter === 'string') {
      this.$filter = filter;
      return this;
    }
    throw new Error('ODataQueryParam.filter only accept string or ODataFilter type parameter');
  }
  /**
   * skip first records
   *
   * @param skip
   */
  skip(skip) {
    this.$skip = skip;
    return this;
  }
  /**
   * limit result max records
   *
   * @param top
   */
  top(top) {
    this.$top = top;
    return this;
  }
  /**
   * select viewed fields
   *
   * @param selects
   */
  select(selects) {
    this.$select = (0, concat_1.default)(this.$select, selects);
    return this;
  }
  /**
   * set order sequence
   *
   * @param fieldOrOrders
   * @param order default desc, disabled when first params is array
   */
  orderby(fieldOrOrders, order = 'desc') {
    if ((0, isArray_1.default)(fieldOrOrders)) {
      return this.orderbyMulti(fieldOrOrders);
    }
    this.$orderby = `${fieldOrOrders} ${order}`;
    return this;
  }
  /**
   * set order by multi field
   *
   * @param fields
   */
  orderbyMulti(fields = []) {
    this.$orderby = (0, join_1.default)(fields.map(f => `${f.field} ${f.order || 'desc'}`), ',');
    return this;
  }
  /**
   * result format, please keep it as json
   *
   * @param format default json
   */
  format(format) {
    this.$format = format;
    return this;
  }
  /**
   * full text search
   *
   * default with fuzzy search, SAP system or OData V4 only
   *
   * @param value
   * @version 4.0.0
   */
  search(value) {
    this.$search = value;
    return this;
  }
  /**
   * expand navigation props
   *
   * @param fields
   * @param replace
   */
  expand(fields, replace = false) {
    if (replace) {
      if (typeof fields == 'string') {
        this.$expand = [fields];
      } else if ((0, isArray_1.default)(fields)) {
        this.$expand = fields;
      }
    } else {
      this.$expand = (0, concat_1.default)(this.$expand, fields);
    }
    return this;
  }
  toString(version = 'v4') {
    const rt = new SearchParams();
    if (this.$format) {
      rt.append('$format', this.$format);
    }
    if (this.$filter) {
      rt.append('$filter', this.$filter.toString());
    }
    if (this.$orderby) {
      rt.append('$orderby', this.$orderby);
    }
    if (this.$search) {
      rt.append('$search', this.$search);
    }
    if (this.$select && this.$select.length > 0) {
      rt.append('$select', (0, join_1.default)((0, uniq_1.default)(this.$select), ','));
    }
    if (this.$skip) {
      rt.append('$skip', this.$skip.toString());
    }
    if (this.$top && this.$top > 0) {
      rt.append('$top', this.$top.toString());
    }
    if (this.$expand && this.$expand.length > 0) {
      rt.append('$expand', this.$expand.join(','));
    }
    switch (version) {
      case 'v2':
        if (this.$count) {
          rt.append('$inlinecount', 'allpages');
        }
        break;
      case 'v4':
        if (this.$count) {
          rt.append('$count', 'true');
        }
        break;
      default:
        break;
    }
    return rt.toString();
  }
}
exports.ODataQueryParam = ODataQueryParam;
exports.ODataParam = ODataQueryParam;

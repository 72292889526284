"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TimeoutError = exports.InValidParameterError = exports.NewDashBaseError = void 0;
class NewDashBaseError extends Error {}
exports.NewDashBaseError = NewDashBaseError;
class InValidParameterError extends NewDashBaseError {}
exports.InValidParameterError = InValidParameterError;
/**
 * TimeoutError
 *
 * raised when the function is not processed finished in a limit period
 */
class TimeoutError extends NewDashBaseError {}
exports.TimeoutError = TimeoutError;
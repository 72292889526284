import {FILTER_EXPRESSIONS} from 'src/app/app.constant';
import {defaultParser, FirstMemberExpressionToken, Token, TokenType} from '@odata/parser';
import {CompositeFilterDescriptor, FilterDescriptor, isCompositeFilterDescriptor, State} from '@progress/kendo-data-query';
import {OOMSFilterDescriptor} from '../components/filters/ooms-filter-descriptor';

const isNumber = (value: any) => typeof value === 'number' && !Number.isNaN(value);

export enum PrimitiveTypeEnum {
  Binary = 'Edm.Binary',
  Boolean = 'Edm.Boolean',
  Byte = 'Edm.Byte',
  Date = 'Edm.Date',
  DateTime = 'Edm.DateTime',
  DateTimeOffset = 'Edm.DateTimeOffset',
  Decimal = 'Edm.Decimal',
  Double = 'Edm.Double',
  Duration = 'Edm.Duration',
  Guid = 'Edm.Guid',
  Int16 = 'Edm.Int16',
  Int32 = 'Edm.Int32',
  Int64 = 'Edm.Int64',
  SByte = 'Edm.SByte',
  Single = 'Edm.Single',
  Stream = 'Edm.Stream',
  String = 'Edm.String',
  TimeOfDay = 'Edm.TimeOfDay',
  Geography = 'Edm.Geography',
  GeographyPoint = 'Edm.GeographyPoint',
  GeographyLineString = 'Edm.GeographyLineString',
  GeographyPolygon = 'Edm.GeographyPolygon',
  GeographyMultiPoint = 'Edm.GeographyMultiPoint',
  GeographyMultiLineString = 'Edm.GeographyMultiLineString',
  GeographyMultiPolygon = 'Edm.GeographyMultiPolygon',
  GeographyCollection = 'Edm.GeographyCollection',
  Geometry = 'Edm.Geometry',
  GeometryPoint = 'Edm.GeometryPoint',
  GeometryLineString = 'Edm.GeometryLineString',
  GeometryPolygon = 'Edm.GeometryPolygon',
  GeometryMultiPoint = 'Edm.GeometryMultiPoint',
  GeometryMultiLineString = 'Edm.GeometryMultiLineString',
  GeometryMultiPolygon = 'Edm.GeometryMultiPolygon',
  GeometryCollection = 'Edm.GeometryCollection',
}


export class QueryUtil {

  static genQueryValueArrays(
    key: string,
    values: string[] | string,
    operater = FILTER_EXPRESSIONS['='],
    coordinatingConjunction = FILTER_EXPRESSIONS['|'],
    isStringValue: boolean = true
  ): string {
    let queryStr = '';
    if (values && values.length) {
      if (values instanceof Array) {
        queryStr += '(';
        let first = true;
        values.forEach(value => {
          if (!first) {
            queryStr += ` ${coordinatingConjunction} `;
          } else {
            first = false;
          }
          if (isStringValue) {
            queryStr += `${key} ${operater} '${value}'`;
          } else {
            queryStr += `${key} ${operater} ${value}`;
          }
        });
        queryStr += ')';
      } else {
        if (isStringValue) {
          queryStr += `${key} ${operater} '${values}'`;
        } else {
          queryStr += `${key} ${operater} ${values}`;
        }
      }
    }
    return queryStr;
  }

  static parseODataQueryString(qs: string): State {
    const state: State = {};
    const res = defaultParser.query(qs);
    for (const token of res.value.options) {
      switch (token.type) {
        case TokenType.Filter:
          const f = QueryUtil.convertFilterToken(token.value);
          state.filter = isCompositeFilterDescriptor(f) ? f : {logic: 'and', filters: [f]};
          break;
      }
    }

    return state;
  }

  static parseODataFilterString(qs: string, filterDescriptors: OOMSFilterDescriptor[] = []): CompositeFilterDescriptor {
    const token = defaultParser.filter(qs);
    const f = QueryUtil.convertFilterToken(token, filterDescriptors);
    return isCompositeFilterDescriptor(f) ? f : {logic: 'and', filters: [f]};
  }
  private static convertFilterToken(filter: Token, filterDescriptors: OOMSFilterDescriptor[] = []): CompositeFilterDescriptor | FilterDescriptor {
    switch (filter.type) {
      case TokenType.BoolParenExpression:
        return QueryUtil.convertFilterToken(filter.value, filterDescriptors);

      case TokenType.EqualsExpression:
      case TokenType.NotEqualsExpression:
      case TokenType.LesserThanExpression:
      case TokenType.LesserOrEqualsExpression:
      case TokenType.GreaterThanExpression:
      case TokenType.GreaterOrEqualsExpression: {
        const fieldName = QueryUtil.GetExpressionMemberName(filter.value.left);
        const value = QueryUtil.GetPrimitiveValue(filter.value.right);
        const op = QueryUtil.mapFilterTokenToOperator(filter);

        const descriptor = filterDescriptors.find(fd => fd.descriptor === fieldName);
        if (!!descriptor) {
          return descriptor.createFilter(op, value);
        } else {
          return {
            operator: op,
            field: fieldName,
            value,
          };
        }
      }

      case TokenType.AndExpression:
      case TokenType.OrExpression:
        return  {
          logic: QueryUtil.mapFilterTokenToOperator(filter),
          filters: [
            QueryUtil.convertFilterToken(filter.value.left, filterDescriptors),
            QueryUtil.convertFilterToken(filter.value.right, filterDescriptors),
          ]
        } as CompositeFilterDescriptor;

      case TokenType.MethodCallExpression: {
        const fieldName = QueryUtil.GetExpressionMemberName(filter.value.parameters[0]);
        const value = QueryUtil.GetPrimitiveValue(filter.value.parameters[1]);
        const op = filter.value.method;

        const descriptor = filterDescriptors.find(fd => fd.descriptor === fieldName);

        if (!!descriptor) {
          return descriptor.createFilter(op, value);
        } else {
          return {
            operator: op,
            field: fieldName,
            value,
          };
        }
      }
    }
  }

  private static mapFilterTokenToOperator(token: Token) {
    switch (token.type) {
      case TokenType.EqualsExpression:
        return 'eq';
      case TokenType.NotEqualsExpression:
        return 'neq';
      case TokenType.LesserThanExpression:
        return 'lt';
      case TokenType.LesserOrEqualsExpression:
        return 'lte';
      case TokenType.GreaterThanExpression:
        return 'gt';
      case TokenType.GreaterOrEqualsExpression:
        return 'gte';
      case TokenType.AndExpression:
        return 'and';
      case TokenType.OrExpression:
        return 'or';
      default:
        return '';
    }
  }

  private static GetPrimitiveValue(token: Token) {
    switch (token.type) {
      case TokenType.Literal:
        switch (token.value) {
          case 'Edm.Boolean':
            return typeof token.raw === 'boolean' ? token.raw : String(token.raw).toLowerCase() === 'true';

          case PrimitiveTypeEnum.String:
            return token.raw.startsWith('\'') ? token.raw.substring(1, token.raw.length - 2) : token.raw;

          case PrimitiveTypeEnum.Int16:
          case PrimitiveTypeEnum.Int32:
          case PrimitiveTypeEnum.Int64:
          case PrimitiveTypeEnum.Single:
          case PrimitiveTypeEnum.Double:
          case PrimitiveTypeEnum.Decimal:
            return isNumber(token.raw) ? token.raw : Number(token.raw);

          default:
            return String(token.raw);
        }

      default:
        return this.GetPrimitiveValue(token.value);
    }
  }

  private static GetExpressionMemberName(token: Token) {
    switch (token.type) {
      case TokenType.ODataIdentifier:
        return token.value.name;
      default:
        return QueryUtil.GetExpressionMemberName(token.value);
    }
  }
}

"use strict";

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Edm = void 0;
const LRUMap_1 = require("@newdash/newdash/functional/LRUMap");
const enum_1 = require("./enum");
const metacode = require("./metacode");
const {
  jsonProperty
} = metacode;
/**
 * Entity Data Model
 */
var Edm;
(function (Edm) {
  class PrimitiveType {
    constructor(className) {
      this.className = className;
    }
    /**
     * @returns edm type code
     */
    toString() {
      return this.className;
    }
    /**
     * create value based current type
     *
     * @param value
     * @returns
     */
    createValue(value) {
      return new PrimitiveTypeValue(value, this);
    }
  }
  Edm.PrimitiveType = PrimitiveType;
  /**
   * primitive literal with value
   */
  class PrimitiveTypeValue {
    constructor(value, type) {
      this.value = value;
      this.type = type;
    }
    /**
     * get the primitive literal type
     *
     * @returns
     */
    getType() {
      return this.type;
    }
    getValue() {
      return this.value;
    }
  }
  Edm.PrimitiveTypeValue = PrimitiveTypeValue;
  Edm.Binary = new PrimitiveType(enum_1.PrimitiveTypeEnum.Binary);
  Edm.Boolean = new PrimitiveType(enum_1.PrimitiveTypeEnum.Boolean);
  Edm.Byte = new PrimitiveType('Edm.Byte');
  Edm.Date = new PrimitiveType('Edm.Date');
  /**
   * @since odata v2
   */
  Edm.DateTime = new PrimitiveType('Edm.DateTime');
  Edm.DateTimeOffset = new PrimitiveType('Edm.DateTimeOffset');
  Edm.Decimal = new PrimitiveType('Edm.Decimal');
  Edm.Double = new PrimitiveType('Edm.Double');
  Edm.Duration = new PrimitiveType('Edm.Duration');
  Edm.Guid = new PrimitiveType('Edm.Guid');
  Edm.Int16 = new PrimitiveType('Edm.Int16');
  Edm.Int32 = new PrimitiveType('Edm.Int32');
  Edm.Int64 = new PrimitiveType('Edm.Int64');
  Edm.SByte = new PrimitiveType('Edm.SByte');
  Edm.Single = new PrimitiveType('Edm.Single');
  Edm.Stream = new PrimitiveType('Edm.Stream');
  Edm.String = new PrimitiveType('Edm.String');
  Edm.TimeOfDay = new PrimitiveType('Edm.TimeOfDay');
  Edm.Geography = new PrimitiveType('Edm.Geography');
  Edm.GeographyPoint = new PrimitiveType('Edm.GeographyPoint');
  Edm.GeographyLineString = new PrimitiveType('Edm.GeographyLineString');
  Edm.GeographyPolygon = new PrimitiveType('Edm.GeographyPolygon');
  Edm.GeographyMultiPoint = new PrimitiveType('Edm.GeographyMultiPoint');
  Edm.GeographyMultiLineString = new PrimitiveType('Edm.GeographyMultiLineString');
  Edm.GeographyMultiPolygon = new PrimitiveType('Edm.GeographyMultiPolygon');
  Edm.GeographyCollection = new PrimitiveType('Edm.GeographyCollection');
  Edm.Geometry = new PrimitiveType('Edm.Geometry');
  Edm.GeometryPoint = new PrimitiveType('Edm.GeometryPoint');
  Edm.GeometryLineString = new PrimitiveType('Edm.GeometryLineString');
  Edm.GeometryPolygon = new PrimitiveType('Edm.GeometryPolygon');
  Edm.GeometryMultiPoint = new PrimitiveType('Edm.GeometryMultiPoint');
  Edm.GeometryMultiLineString = new PrimitiveType('Edm.GeometryMultiLineString');
  Edm.GeometryMultiPolygon = new PrimitiveType('Edm.GeometryMultiPolygon');
  Edm.GeometryCollection = new PrimitiveType('Edm.GeometryCollection');
  const MemberAttribute = metacode.MemberAttribute;
  const parse = metacode.parse;
  const required = metacode.required;
  const defaultValue = metacode.defaultValue;
  const parseAs = metacode.parseAs;
  const AttributeFunctionChain = metacode.AttributeFunctionChain;
  const mapArray = (sourceField, factory) => new metacode.AttributeFunctionChain((d, i) => d[sourceField], (props, i) => Array.isArray(props) ? props : props ? [props] : [], (props, i) => props.map(prop => factory(prop, i)));
  const primitiveAnnotationValue = sourceField => new metacode.AttributeFunctionChain((d, i) => {
    if (d['collection'] && d['collection'][0] && Array.isArray(d['collection'][0][sourceField]) && !d[sourceField]) {
      return d['collection'][0][sourceField].map(x => x.text);
    }
    const props = d[sourceField];
    if (Array.isArray(props)) {
      return props.filter(x => 'text' in x).map(x => x.text)[0];
    }
    return props;
  });
  const annotationTypeSelector = source => {
    for (const i in Edm.AnnotationTypes) {
      if (i in source || source['collection'] && source['collection'][0] && i in source['collection'][0]) {
        return Edm.AnnotationTypes[i];
      }
    }
    return Annotation;
  };
  class EdmItemBase {
    constructor(definition, parent) {
      this.parent = parent;
      definition && this.loadFrom(definition);
    }
    _tryGetCache(key, producer) {
      // lazy create cache
      if (this._cache === undefined) {
        this._cache = new LRUMap_1.LRUMap();
      }
      if (!this._cache.has(key)) {
        this._cache.set(key, producer());
      }
      return this._cache.get(key);
    }
    getAnnotationsByTerm(term) {
      return this._tryGetCache(`_type_${term}_`, () => {
        var _a, _b;
        const rt = [];
        (_b = (_a = this['annotations']) === null || _a === void 0 ? void 0 : _a.map) === null || _b === void 0 ? void 0 : _b.call(_a, annotation => {
          if (annotation.term === term) {
            rt.push(rt);
          }
        });
        return rt;
      });
    }
    loadFrom(definition) {
      const proto = Object.getPrototypeOf(this);
      MemberAttribute.getMembers(proto).forEach(membername => {
        const parser = MemberAttribute.getAttributeValue(proto, membername, 'serialize');
        if (parser) {
          const v = parser.invoke(definition, this);
          this[membername] = v;
        }
      });
    }
  }
  Edm.EdmItemBase = EdmItemBase;
  class Property extends EdmItemBase {}
  __decorate([parse, required, __metadata("design:type", String)], Property.prototype, "name", void 0);
  __decorate([parse, required, __metadata("design:type", String)], Property.prototype, "type", void 0);
  __decorate([parse, defaultValue(true), __metadata("design:type", Boolean)], Property.prototype, "nullable", void 0);
  __decorate([parse, __metadata("design:type", Number)], Property.prototype, "maxLength", void 0);
  __decorate([parse, __metadata("design:type", Number)], Property.prototype, "precision", void 0);
  __decorate([parse, __metadata("design:type", Number)], Property.prototype, "scale", void 0);
  __decorate([parse, __metadata("design:type", Boolean)], Property.prototype, "unicode", void 0);
  __decorate([parse, defaultValue(0), __metadata("design:type", Number)], Property.prototype, "SRID", void 0);
  __decorate([parse, __metadata("design:type", Object)], Property.prototype, "defaultValue", void 0);
  __decorate([parse, __metadata("design:type", Object)], Property.prototype, "concurrencyMode", void 0);
  __decorate([jsonProperty('annotation'), parseAs(mapArray('annotation', (prop, i) => new (annotationTypeSelector(prop))(prop, i))), __metadata("design:type", Array)], Property.prototype, "annotations", void 0);
  Edm.Property = Property;
  class NavigationProperty extends EdmItemBase {}
  __decorate([parse, required, __metadata("design:type", String)], NavigationProperty.prototype, "name", void 0);
  __decorate([parse, required, __metadata("design:type", String)], NavigationProperty.prototype, "type", void 0);
  __decorate([parse, defaultValue(true), __metadata("design:type", Boolean)], NavigationProperty.prototype, "nullable", void 0);
  __decorate([parse, __metadata("design:type", String)], NavigationProperty.prototype, "partner", void 0);
  __decorate([parse, __metadata("design:type", Boolean)], NavigationProperty.prototype, "containsTarget", void 0);
  __decorate([jsonProperty('referentialConstraint'), parseAs(mapArray('referentialConstraint', (prop, i) => new ReferentialConstraint(prop, i))), __metadata("design:type", Array)], NavigationProperty.prototype, "referentialConstraints", void 0);
  __decorate([jsonProperty('annotation'), parseAs(mapArray('annotation', (prop, i) => new (annotationTypeSelector(prop))(prop, i))), __metadata("design:type", Array)], NavigationProperty.prototype, "annotations", void 0);
  Edm.NavigationProperty = NavigationProperty;
  class ReferentialConstraint extends EdmItemBase {}
  __decorate([parse, required, __metadata("design:type", String)], ReferentialConstraint.prototype, "property", void 0);
  __decorate([parse, required, __metadata("design:type", String)], ReferentialConstraint.prototype, "referencedProperty", void 0);
  Edm.ReferentialConstraint = ReferentialConstraint;
  class PropertyRef extends EdmItemBase {}
  __decorate([parse, required, __metadata("design:type", String)], PropertyRef.prototype, "name", void 0);
  __decorate([parse, __metadata("design:type", String)], PropertyRef.prototype, "alias", void 0);
  Edm.PropertyRef = PropertyRef;
  class Key extends EdmItemBase {}
  __decorate([jsonProperty('propertyRef'), parseAs(mapArray('propertyRef', (prop, i) => new PropertyRef(prop, i))), __metadata("design:type", Array)], Key.prototype, "propertyRefs", void 0);
  Edm.Key = Key;
  class EntityType extends EdmItemBase {
    getPropertyByName(propertyName) {
      return this._tryGetCache(`_prop_${propertyName}_`, () => {
        for (const property of this.properties) {
          if (property.name === propertyName) {
            return property;
          }
          return null;
        }
      });
    }
  }
  __decorate([parse, required, __metadata("design:type", String)], EntityType.prototype, "name", void 0);
  __decorate([parseAs(new AttributeFunctionChain((d, i) => d.key, (props, i) => props !== null && props !== void 0 ? props : [], (props, i) => {
    var _a;
    return (_a = props === null || props === void 0 ? void 0 : props.map) === null || _a === void 0 ? void 0 : _a.call(props, prop => new Key(prop, i));
  }, props => props === null || props === void 0 ? void 0 : props[0])), jsonProperty('key', instance => [instance.key]), __metadata("design:type", Key)], EntityType.prototype, "key", void 0);
  __decorate([parse, __metadata("design:type", String)], EntityType.prototype, "baseType", void 0);
  __decorate([parse, __metadata("design:type", Boolean)], EntityType.prototype, "abstract", void 0);
  __decorate([parse, __metadata("design:type", Boolean)], EntityType.prototype, "openType", void 0);
  __decorate([parse, __metadata("design:type", Boolean)], EntityType.prototype, "hasStream", void 0);
  __decorate([jsonProperty('property'), parseAs(mapArray('property', (prop, i) => new Property(prop, i))), __metadata("design:type", Array)], EntityType.prototype, "properties", void 0);
  __decorate([jsonProperty('navigationProperty'), parseAs(mapArray('navigationProperty', (prop, i) => new NavigationProperty(prop, i))), __metadata("design:type", Array)], EntityType.prototype, "navigationProperties", void 0);
  __decorate([jsonProperty('annotation'), parseAs(mapArray('annotation', (prop, i) => new (annotationTypeSelector(prop))(prop, i))), __metadata("design:type", Array)], EntityType.prototype, "annotations", void 0);
  Edm.EntityType = EntityType;
  class ComplexType extends EdmItemBase {}
  __decorate([parse, required, __metadata("design:type", String)], ComplexType.prototype, "name", void 0);
  __decorate([parse, __metadata("design:type", String)], ComplexType.prototype, "baseType", void 0);
  __decorate([parse, __metadata("design:type", Boolean)], ComplexType.prototype, "abstract", void 0);
  __decorate([parse, __metadata("design:type", Boolean)], ComplexType.prototype, "openType", void 0);
  __decorate([parse, __metadata("design:type", Boolean)], ComplexType.prototype, "hasStream", void 0);
  __decorate([jsonProperty('property'), parseAs(mapArray('property', (prop, i) => new Property(prop, i))), __metadata("design:type", Array)], ComplexType.prototype, "properties", void 0);
  __decorate([jsonProperty('navigationProperty'), parseAs(mapArray('navigationProperty', (prop, i) => new NavigationProperty(prop, i))), __metadata("design:type", Array)], ComplexType.prototype, "navigationProperties", void 0);
  __decorate([jsonProperty('annotation'), parseAs(mapArray('annotation', (prop, i) => new (annotationTypeSelector(prop))(prop, i))), __metadata("design:type", Array)], ComplexType.prototype, "annotations", void 0);
  Edm.ComplexType = ComplexType;
  class Parameter extends EdmItemBase {}
  __decorate([parse, required, __metadata("design:type", String)], Parameter.prototype, "name", void 0);
  __decorate([parse, required, __metadata("design:type", String)], Parameter.prototype, "type", void 0);
  __decorate([parse, defaultValue(true), __metadata("design:type", Boolean)], Parameter.prototype, "nullable", void 0);
  __decorate([parse, __metadata("design:type", Number)], Parameter.prototype, "maxLength", void 0);
  __decorate([parse, __metadata("design:type", Number)], Parameter.prototype, "precision", void 0);
  __decorate([parse, __metadata("design:type", Number)], Parameter.prototype, "scale", void 0);
  __decorate([parse, __metadata("design:type", Boolean)], Parameter.prototype, "unicode", void 0);
  __decorate([parse, defaultValue(0), __metadata("design:type", Number)], Parameter.prototype, "SRID", void 0);
  __decorate([jsonProperty('annotation'), parseAs(mapArray('annotation', (prop, i) => new (annotationTypeSelector(prop))(prop, i))), __metadata("design:type", Array)], Parameter.prototype, "annotations", void 0);
  Edm.Parameter = Parameter;
  class ReturnType extends EdmItemBase {}
  __decorate([parse, __metadata("design:type", String)], ReturnType.prototype, "type", void 0);
  __decorate([parse, defaultValue(true), __metadata("design:type", Boolean)], ReturnType.prototype, "nullable", void 0);
  __decorate([jsonProperty('annotation'), parseAs(mapArray('annotation', (prop, i) => new (annotationTypeSelector(prop))(prop, i))), __metadata("design:type", Array)], ReturnType.prototype, "annotations", void 0);
  Edm.ReturnType = ReturnType;
  class Invokable extends EdmItemBase {}
  Edm.Invokable = Invokable;
  class Action extends Invokable {}
  __decorate([parse, required, __metadata("design:type", String)], Action.prototype, "name", void 0);
  __decorate([parse, __metadata("design:type", Boolean)], Action.prototype, "isBound", void 0);
  __decorate([parse, __metadata("design:type", String)], Action.prototype, "entitySetPath", void 0);
  __decorate([jsonProperty('parameter'), parseAs(mapArray('parameter', (prop, i) => new Parameter(prop, i))), __metadata("design:type", Array)], Action.prototype, "parameters", void 0);
  __decorate([parseAs(new AttributeFunctionChain((d, i) => d.returnType, (rt, i) => new ReturnType(rt, i))), __metadata("design:type", ReturnType)], Action.prototype, "returnType", void 0);
  __decorate([jsonProperty('annotation'), parseAs(mapArray('annotation', (prop, i) => new (annotationTypeSelector(prop))(prop, i))), __metadata("design:type", Array)], Action.prototype, "annotations", void 0);
  Edm.Action = Action;
  class Function extends Invokable {}
  __decorate([parse, required, __metadata("design:type", String)], Function.prototype, "name", void 0);
  __decorate([parse, __metadata("design:type", Boolean)], Function.prototype, "isBound", void 0);
  __decorate([parse, __metadata("design:type", String)], Function.prototype, "entitySetPath", void 0);
  __decorate([jsonProperty('parameter'), parseAs(mapArray('parameter', (prop, i) => new Parameter(prop, i))), __metadata("design:type", Array)], Function.prototype, "parameters", void 0);
  __decorate([parseAs(new AttributeFunctionChain((d, i) => d.returnType, (rt, i) => new ReturnType(rt, i))), __metadata("design:type", ReturnType)], Function.prototype, "returnType", void 0);
  __decorate([parse, __metadata("design:type", Boolean)], Function.prototype, "isComposable", void 0);
  __decorate([jsonProperty('annotation'), parseAs(mapArray('annotation', (prop, i) => new (annotationTypeSelector(prop))(prop, i))), __metadata("design:type", Array)], Function.prototype, "annotations", void 0);
  Edm.Function = Function;
  class Member extends EdmItemBase {}
  __decorate([parse, required, __metadata("design:type", String)], Member.prototype, "name", void 0);
  __decorate([parse, __metadata("design:type", Number)], Member.prototype, "value", void 0);
  __decorate([jsonProperty('annotation'), parseAs(mapArray('annotation', (prop, i) => new (annotationTypeSelector(prop))(prop, i))), __metadata("design:type", Array)], Member.prototype, "annotations", void 0);
  Edm.Member = Member;
  class EnumType extends EdmItemBase {}
  __decorate([parse, required, __metadata("design:type", String)], EnumType.prototype, "name", void 0);
  __decorate([parse, required, __metadata("design:type", String)], EnumType.prototype, "namespace", void 0);
  __decorate([parse
  //@oneOf(Edm.Byte, Edm.SByte, Edm.Int16, Edm.Int32, Edm.Int64)
  , defaultValue(Edm.Int32), __metadata("design:type", PrimitiveType)], EnumType.prototype, "underlyingType", void 0);
  __decorate([parse, __metadata("design:type", Boolean)], EnumType.prototype, "isFlags", void 0);
  __decorate([jsonProperty('member'), parseAs(mapArray('member', (prop, i) => new Member(prop, i))), __metadata("design:type", Array)], EnumType.prototype, "members", void 0);
  __decorate([jsonProperty('annotation'), parseAs(mapArray('annotation', (prop, i) => new (annotationTypeSelector(prop))(prop, i))), __metadata("design:type", Array)], EnumType.prototype, "annotations", void 0);
  Edm.EnumType = EnumType;
  class EntitySet extends EdmItemBase {}
  __decorate([parse, required, __metadata("design:type", String)], EntitySet.prototype, "name", void 0);
  __decorate([parse, required, __metadata("design:type", String)], EntitySet.prototype, "entityType", void 0);
  __decorate([jsonProperty('annotation'), parseAs(mapArray('annotation', (prop, i) => new (annotationTypeSelector(prop))(prop, i))), __metadata("design:type", Array)], EntitySet.prototype, "annotations", void 0);
  Edm.EntitySet = EntitySet;
  class ActionImport extends EdmItemBase {}
  __decorate([parse, required, __metadata("design:type", String)], ActionImport.prototype, "name", void 0);
  __decorate([parse, required, __metadata("design:type", String)], ActionImport.prototype, "action", void 0);
  __decorate([jsonProperty('annotation'), parseAs(mapArray('annotation', (prop, i) => new (annotationTypeSelector(prop))(prop, i))), __metadata("design:type", Array)], ActionImport.prototype, "annotations", void 0);
  Edm.ActionImport = ActionImport;
  class FunctionImport extends EdmItemBase {}
  __decorate([parse, required, __metadata("design:type", String)], FunctionImport.prototype, "name", void 0);
  __decorate([parse, required, __metadata("design:type", String)], FunctionImport.prototype, "function", void 0);
  __decorate([parse, defaultValue(false), __metadata("design:type", Boolean)], FunctionImport.prototype, "includeInServiceDocument", void 0);
  __decorate([jsonProperty('annotation'), parseAs(mapArray('annotation', (prop, i) => new (annotationTypeSelector(prop))(prop, i))), __metadata("design:type", Array)], FunctionImport.prototype, "annotations", void 0);
  Edm.FunctionImport = FunctionImport;
  class EntityContainer extends EdmItemBase {}
  __decorate([parse, __metadata("design:type", String)], EntityContainer.prototype, "name", void 0);
  __decorate([jsonProperty('entitySet'), parseAs(mapArray('entitySet', (prop, i) => new EntitySet(prop, i))), __metadata("design:type", Array)], EntityContainer.prototype, "entitySets", void 0);
  __decorate([jsonProperty('actionImport'), parseAs(mapArray('actionImport', (prop, i) => new ActionImport(prop, i))), __metadata("design:type", Array)], EntityContainer.prototype, "actionImports", void 0);
  __decorate([jsonProperty('functionImport'), parseAs(mapArray('functionImport', (prop, i) => new FunctionImport(prop, i))), __metadata("design:type", Array)], EntityContainer.prototype, "functionImports", void 0);
  Edm.EntityContainer = EntityContainer;
  // "Name", "UnderlyingType", "MaxLength", "Unicode", "Precision", "Scale", "SRID"
  class TypeDefinition extends EdmItemBase {}
  __decorate([parse, __metadata("design:type", String)], TypeDefinition.prototype, "name", void 0);
  __decorate([parse, __metadata("design:type", PrimitiveType)], TypeDefinition.prototype, "underlyingType", void 0);
  __decorate([parse, __metadata("design:type", Number)], TypeDefinition.prototype, "maxLength", void 0);
  __decorate([parse, __metadata("design:type", Boolean)], TypeDefinition.prototype, "unicode", void 0);
  __decorate([parse, __metadata("design:type", Number)], TypeDefinition.prototype, "precision", void 0);
  __decorate([parse, __metadata("design:type", Number)], TypeDefinition.prototype, "scale", void 0);
  __decorate([parse, defaultValue(0), __metadata("design:type", Number)], TypeDefinition.prototype, "SRID", void 0);
  __decorate([jsonProperty('annotation'), parseAs(mapArray('annotation', (prop, i) => new (annotationTypeSelector(prop))(prop, i))), __metadata("design:type", Array)], TypeDefinition.prototype, "annotations", void 0);
  Edm.TypeDefinition = TypeDefinition;
  class Schema extends EdmItemBase {}
  __decorate([parse, required, __metadata("design:type", String)], Schema.prototype, "namespace", void 0);
  __decorate([parse, __metadata("design:type", String)], Schema.prototype, "alias", void 0);
  __decorate([jsonProperty('enumType'), parseAs(mapArray('enumType', (prop, i) => new EnumType(prop, i))), __metadata("design:type", Array)], Schema.prototype, "enumTypes", void 0);
  __decorate([jsonProperty('typeDefinition'), parseAs(mapArray('typeDefinition', (prop, i) => new TypeDefinition(prop, i))), __metadata("design:type", Array)], Schema.prototype, "typeDefinitions", void 0);
  __decorate([jsonProperty('complexType'), parseAs(mapArray('complexType', (prop, i) => new ComplexType(prop, i))), __metadata("design:type", Array)], Schema.prototype, "complexTypes", void 0);
  __decorate([jsonProperty('entityType'), parseAs(mapArray('entityType', (prop, i) => new EntityType(prop, i))), __metadata("design:type", Array)], Schema.prototype, "entityTypes", void 0);
  __decorate([jsonProperty('action'), parseAs(mapArray('action', (prop, i) => new Action(prop, i))), __metadata("design:type", Array)], Schema.prototype, "actions", void 0);
  __decorate([jsonProperty('function'), parseAs(mapArray('function', (prop, i) => new Edm.Function(prop, i))), __metadata("design:type", Array)], Schema.prototype, "functions", void 0);
  __decorate([jsonProperty('entityContainer'), parseAs(mapArray('entityContainer', (prop, i) => new Edm.EntityContainer(prop, i))), __metadata("design:type", Array)], Schema.prototype, "entityContainer", void 0);
  __decorate([jsonProperty('annotations'), parseAs(mapArray('annotations', (prop, i) => new Edm.Annotations(prop, i))), __metadata("design:type", Array)], Schema.prototype, "annotations", void 0);
  Edm.Schema = Schema;
  class DataServices extends EdmItemBase {}
  __decorate([jsonProperty('schema'), parseAs(mapArray('schema', (prop, i) => new Schema(prop, i))), __metadata("design:type", Array)], DataServices.prototype, "schemas", void 0);
  Edm.DataServices = DataServices;
  class Reference extends EdmItemBase {}
  __decorate([parse, __metadata("design:type", String)], Reference.prototype, "uri", void 0);
  __decorate([jsonProperty('include'), parseAs(mapArray('include', (prop, i) => new ReferenceInclude(prop, i))), __metadata("design:type", Array)], Reference.prototype, "includes", void 0);
  Edm.Reference = Reference;
  class ReferenceInclude extends EdmItemBase {}
  __decorate([parse, __metadata("design:type", String)], ReferenceInclude.prototype, "namespace", void 0);
  __decorate([parse, __metadata("design:type", String)], ReferenceInclude.prototype, "alias", void 0);
  Edm.ReferenceInclude = ReferenceInclude;
  class Edmx extends EdmItemBase {
    constructor() {
      super(...arguments);
      this.version = '4.0';
    }
  }
  __decorate([parseAs(new AttributeFunctionChain(edm => new Edm.DataServices(edm.dataServices))), __metadata("design:type", DataServices)], Edmx.prototype, "dataServices", void 0);
  __decorate([jsonProperty('reference'), parseAs(mapArray('reference', (prop, i) => new Reference(prop, i))), __metadata("design:type", Array)], Edmx.prototype, "references", void 0);
  Edm.Edmx = Edmx;
  class Annotations extends EdmItemBase {}
  __decorate([parse, required, __metadata("design:type", String)], Annotations.prototype, "target", void 0);
  __decorate([parse, __metadata("design:type", String)], Annotations.prototype, "qualifier", void 0);
  __decorate([jsonProperty('annotation'), parseAs(mapArray('annotation', (prop, i) => new (annotationTypeSelector(prop))(prop, i))), __metadata("design:type", Array)], Annotations.prototype, "annotations", void 0);
  Edm.Annotations = Annotations;
  class Annotation extends EdmItemBase {
    constructor() {
      super(...arguments);
      this.annotationType = 'Unknown';
    }
  }
  __decorate([parse, __metadata("design:type", String)], Annotation.prototype, "term", void 0);
  __decorate([parse, __metadata("design:type", String)], Annotation.prototype, "qualifier", void 0);
  __decorate([parse, __metadata("design:type", String)], Annotation.prototype, "path", void 0);
  Edm.Annotation = Annotation;
  class BinaryAnnotation extends Annotation {
    constructor() {
      super(...arguments);
      this.annotationType = 'Binary';
    }
  }
  __decorate([parseAs(primitiveAnnotationValue('binary')), __metadata("design:type", Object)], BinaryAnnotation.prototype, "binary", void 0);
  Edm.BinaryAnnotation = BinaryAnnotation;
  class BoolAnnotation extends Annotation {
    constructor() {
      super(...arguments);
      this.annotationType = 'Bool';
    }
  }
  __decorate([parseAs(primitiveAnnotationValue('bool')), __metadata("design:type", Object)], BoolAnnotation.prototype, "bool", void 0);
  Edm.BoolAnnotation = BoolAnnotation;
  class DateAnnotation extends Annotation {
    constructor() {
      super(...arguments);
      this.annotationType = 'Date';
    }
  }
  __decorate([parseAs(primitiveAnnotationValue('date')), __metadata("design:type", Object)], DateAnnotation.prototype, "date", void 0);
  Edm.DateAnnotation = DateAnnotation;
  class DateTimeOffsetAnnotation extends Annotation {
    constructor() {
      super(...arguments);
      this.annotationType = 'DateTimeOffset';
    }
  }
  __decorate([parseAs(primitiveAnnotationValue('dateTimeOffset')), __metadata("design:type", Object)], DateTimeOffsetAnnotation.prototype, "dateTimeOffset", void 0);
  Edm.DateTimeOffsetAnnotation = DateTimeOffsetAnnotation;
  class DecimalAnnotation extends Annotation {
    constructor() {
      super(...arguments);
      this.annotationType = 'Decimal';
    }
  }
  __decorate([parseAs(primitiveAnnotationValue('decimal')), __metadata("design:type", Object)], DecimalAnnotation.prototype, "decimal", void 0);
  Edm.DecimalAnnotation = DecimalAnnotation;
  class DurationAnnotation extends Annotation {
    constructor() {
      super(...arguments);
      this.annotationType = 'Duration';
    }
  }
  __decorate([parseAs(primitiveAnnotationValue('duration')), __metadata("design:type", Object)], DurationAnnotation.prototype, "duration", void 0);
  Edm.DurationAnnotation = DurationAnnotation;
  class EnumMemberAnnotation extends Annotation {
    constructor() {
      super(...arguments);
      this.annotationType = 'EnumMember';
    }
  }
  __decorate([parseAs(primitiveAnnotationValue('enumMember')), __metadata("design:type", Object)], EnumMemberAnnotation.prototype, "enumMember", void 0);
  Edm.EnumMemberAnnotation = EnumMemberAnnotation;
  class FloatAnnotation extends Annotation {
    constructor() {
      super(...arguments);
      this.annotationType = 'Float';
    }
  }
  __decorate([parseAs(primitiveAnnotationValue('float')), __metadata("design:type", Object)], FloatAnnotation.prototype, "float", void 0);
  Edm.FloatAnnotation = FloatAnnotation;
  class GuidAnnotation extends Annotation {
    constructor() {
      super(...arguments);
      this.annotationType = 'Guid';
    }
  }
  __decorate([parseAs(primitiveAnnotationValue('guid')), __metadata("design:type", Object)], GuidAnnotation.prototype, "guid", void 0);
  Edm.GuidAnnotation = GuidAnnotation;
  class IntAnnotation extends Annotation {
    constructor() {
      super(...arguments);
      this.annotationType = 'Int';
    }
  }
  __decorate([parseAs(primitiveAnnotationValue('int')), __metadata("design:type", Object)], IntAnnotation.prototype, "int", void 0);
  Edm.IntAnnotation = IntAnnotation;
  class StringAnnotation extends Annotation {
    constructor() {
      super(...arguments);
      this.annotationType = 'String';
    }
  }
  __decorate([parseAs(primitiveAnnotationValue('string')), __metadata("design:type", Object)], StringAnnotation.prototype, "string", void 0);
  Edm.StringAnnotation = StringAnnotation;
  class TimeOfDayAnnotation extends Annotation {
    constructor() {
      super(...arguments);
      this.annotationType = 'TimeOfDay';
    }
  }
  __decorate([parseAs(primitiveAnnotationValue('timeOfDay')), __metadata("design:type", Object)], TimeOfDayAnnotation.prototype, "timeOfDay", void 0);
  Edm.TimeOfDayAnnotation = TimeOfDayAnnotation;
  class PropertyPathAnnotation extends Annotation {
    constructor() {
      super(...arguments);
      this.annotationType = 'PropertyPath';
    }
    toJson() {
      var _a;
      return {
        term: this.annotationType,
        collection: [{
          propertyPath: (_a = this.propertyPaths) === null || _a === void 0 ? void 0 : _a.map(s => ({
            text: s
          }))
        }]
      };
    }
  }
  __decorate([jsonProperty('propertyPath'), parseAs(primitiveAnnotationValue('propertyPath')), __metadata("design:type", Array)], PropertyPathAnnotation.prototype, "propertyPaths", void 0);
  Edm.PropertyPathAnnotation = PropertyPathAnnotation;
  class NavigationPropertyPathAnnotation extends Annotation {
    constructor() {
      super(...arguments);
      this.annotationType = 'NavigationPropertyPath';
    }
  }
  __decorate([parseAs(primitiveAnnotationValue('propertyPath')), __metadata("design:type", Object)], NavigationPropertyPathAnnotation.prototype, "navigationPropertyPaths", void 0);
  Edm.NavigationPropertyPathAnnotation = NavigationPropertyPathAnnotation;
  class AnnotationPathAnnotation extends Annotation {
    constructor() {
      super(...arguments);
      this.annotationType = 'AnnotationPath';
    }
  }
  __decorate([parseAs(primitiveAnnotationValue('annotationPath')), __metadata("design:type", Object)], AnnotationPathAnnotation.prototype, "annotationPaths", void 0);
  Edm.AnnotationPathAnnotation = AnnotationPathAnnotation;
  class NullAnnotation extends Annotation {
    constructor() {
      super(...arguments);
      this.annotationType = 'Null';
    }
  }
  __decorate([parseAs(primitiveAnnotationValue('null')), __metadata("design:type", Array)], NullAnnotation.prototype, "null", void 0);
  Edm.NullAnnotation = NullAnnotation;
  Edm.AnnotationTypes = {
    binary: BinaryAnnotation,
    bool: BoolAnnotation,
    date: DateAnnotation,
    dateTimeOffset: DateTimeOffsetAnnotation,
    decimal: DecimalAnnotation,
    duration: DurationAnnotation,
    enumMember: EnumMemberAnnotation,
    float: FloatAnnotation,
    guid: GuidAnnotation,
    int: IntAnnotation,
    string: StringAnnotation,
    timeOfDay: TimeOfDayAnnotation,
    propertyPath: PropertyPathAnnotation,
    navigationPropertyPath: NavigationPropertyPathAnnotation,
    annotationPath: AnnotationPathAnnotation,
    null: NullAnnotation
  };
  Edm.toAnnotationTypeKey = value => {
    for (const [key, type] of Object.entries(Edm.AnnotationTypes)) {
      if (value instanceof type) {
        return key;
      }
    }
  };
})(Edm = exports.Edm || (exports.Edm = {}));

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getTag = void 0;
const GLOBAL_1 = require("./GLOBAL");
const nullTag = '[object Null]';
const undefinedTag = '[object Undefined]';
/**
 * @ignore
 * @param value
 */
function getRawTag(value) {
  var isOwn = Object.prototype.hasOwnProperty.call(value, Symbol.toStringTag);
  const tag = value[Symbol.toStringTag];
  try {
    value[Symbol.toStringTag] = undefined;
    var unmasked = true;
  } catch (e) {}
  var result = Object.prototype.toString.call(value);
  if (unmasked) {
    if (isOwn) {
      value[GLOBAL_1.symToStringTag] = tag;
    } else {
      delete value[GLOBAL_1.symToStringTag];
    }
  }
  return result;
}
/**
 * Gets the `toStringTag` of `value`.
 *
 * @private
 * @param value The value to query.
 * @returns Returns the `toStringTag`.
 */
function getTag(value) {
  if (value == null) {
    return value === undefined ? undefinedTag : nullTag;
  }
  return GLOBAL_1.symToStringTag && GLOBAL_1.symToStringTag in Object(value) ? getRawTag(value) : Object.prototype.toString.call(value);
}
exports.getTag = getTag;
exports.default = getTag;
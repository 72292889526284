"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PrimitiveTypeEnum = void 0;
var PrimitiveTypeEnum;
(function (PrimitiveTypeEnum) {
  PrimitiveTypeEnum["Binary"] = "Edm.Binary";
  PrimitiveTypeEnum["Boolean"] = "Edm.Boolean";
  PrimitiveTypeEnum["Byte"] = "Edm.Byte";
  PrimitiveTypeEnum["Date"] = "Edm.Date";
  PrimitiveTypeEnum["DateTime"] = "Edm.DateTime";
  PrimitiveTypeEnum["DateTimeOffset"] = "Edm.DateTimeOffset";
  PrimitiveTypeEnum["Decimal"] = "Edm.Decimal";
  PrimitiveTypeEnum["Double"] = "Edm.Double";
  PrimitiveTypeEnum["Duration"] = "Edm.Duration";
  PrimitiveTypeEnum["Guid"] = "Edm.Guid";
  PrimitiveTypeEnum["Int16"] = "Edm.Int16";
  PrimitiveTypeEnum["Int32"] = "Edm.Int32";
  PrimitiveTypeEnum["Int64"] = "Edm.Int64";
  PrimitiveTypeEnum["SByte"] = "Edm.SByte";
  PrimitiveTypeEnum["Single"] = "Edm.Single";
  PrimitiveTypeEnum["Stream"] = "Edm.Stream";
  PrimitiveTypeEnum["String"] = "Edm.String";
  PrimitiveTypeEnum["TimeOfDay"] = "Edm.TimeOfDay";
  PrimitiveTypeEnum["Geography"] = "Edm.Geography";
  PrimitiveTypeEnum["GeographyPoint"] = "Edm.GeographyPoint";
  PrimitiveTypeEnum["GeographyLineString"] = "Edm.GeographyLineString";
  PrimitiveTypeEnum["GeographyPolygon"] = "Edm.GeographyPolygon";
  PrimitiveTypeEnum["GeographyMultiPoint"] = "Edm.GeographyMultiPoint";
  PrimitiveTypeEnum["GeographyMultiLineString"] = "Edm.GeographyMultiLineString";
  PrimitiveTypeEnum["GeographyMultiPolygon"] = "Edm.GeographyMultiPolygon";
  PrimitiveTypeEnum["GeographyCollection"] = "Edm.GeographyCollection";
  PrimitiveTypeEnum["Geometry"] = "Edm.Geometry";
  PrimitiveTypeEnum["GeometryPoint"] = "Edm.GeometryPoint";
  PrimitiveTypeEnum["GeometryLineString"] = "Edm.GeometryLineString";
  PrimitiveTypeEnum["GeometryPolygon"] = "Edm.GeometryPolygon";
  PrimitiveTypeEnum["GeometryMultiPoint"] = "Edm.GeometryMultiPoint";
  PrimitiveTypeEnum["GeometryMultiLineString"] = "Edm.GeometryMultiLineString";
  PrimitiveTypeEnum["GeometryMultiPolygon"] = "Edm.GeometryMultiPolygon";
  PrimitiveTypeEnum["GeometryCollection"] = "Edm.GeometryCollection";
})(PrimitiveTypeEnum = exports.PrimitiveTypeEnum || (exports.PrimitiveTypeEnum = {}));

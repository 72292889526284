"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getJsonProperty = exports.jsonProperty = exports.typeArgument = exports.parseAs = exports.parse = exports.parseAttribute = exports.defaultValue = exports.defaultValueAttribute = exports.required = exports.ParseAttribute = exports.AttributeFunctionChain = exports.MemberAttribute = void 0;
require("reflect-metadata");
const isUndefined_1 = require("@newdash/newdash/isUndefined");
const definitionPropName = 'definition';
class MemberAttribute {
  constructor(attributeName) {
    this.attributeName = attributeName;
  }
  registerMember(target, key) {
    const def = target[definitionPropName] = target[definitionPropName] || {};
    const md = def.members || [];
    if (md.indexOf(key) < 0) {
      md.push(key);
    }
    def.members = md;
  }
  getDecoratorValue(target, key, presentedValue) {
    return presentedValue;
  }
  decorate(value) {
    return (target, key, descriptor) => {
      this.registerMember(target, key);
      const decoratorValue = this.getDecoratorValue(target, key, value);
      target[definitionPropName][this.attributeName] = target[definitionPropName][this.attributeName] || {};
      target[definitionPropName][this.attributeName][key] = decoratorValue;
    };
  }
  get decorator() {
    return this.decorate();
  }
  static getMembers(target) {
    return target[definitionPropName].members;
  }
  static getAttributeValue(target, memberName, attributeName) {
    return ((target[definitionPropName] || {})[attributeName] || {})[memberName];
  }
}
exports.MemberAttribute = MemberAttribute;
class AttributeFunctionChain {
  constructor(...steps) {
    this.steps = [];
    this.steps = steps;
  }
  invoke(definition, instance) {
    let result = definition;
    this.steps.forEach(fn => {
      result = fn(result, instance);
    });
    return result;
  }
}
exports.AttributeFunctionChain = AttributeFunctionChain;
class ParseAttribute extends MemberAttribute {
  constructor() {
    super('serialize');
  }
  getDecoratorValue(target, key, presentedValue) {
    if (!(0, isUndefined_1.isUndefined)(presentedValue)) {
      return presentedValue;
    }
    return new AttributeFunctionChain(d => d[key]);
  }
}
exports.ParseAttribute = ParseAttribute;
exports.required = new MemberAttribute('required').decorate(true);
exports.defaultValueAttribute = new MemberAttribute('defaultValue');
exports.defaultValue = exports.defaultValueAttribute.decorate.bind(exports.defaultValueAttribute);
exports.parseAttribute = new ParseAttribute();
exports.parse = exports.parseAttribute.decorator;
exports.parseAs = exports.parseAttribute.decorate.bind(exports.parseAttribute);
exports.typeArgument = new MemberAttribute('typeArgument');
const KEY_JSON_PROP = 'KEY_JSON_PROP';
const jsonProperty = function (propertyName, toJson) {
  return function (target, targetKey) {
    Reflect.defineMetadata(KEY_JSON_PROP, {
      propertyName,
      toJson
    }, target, targetKey);
  };
};
exports.jsonProperty = jsonProperty;
function getJsonProperty(target, targetKey) {
  return Reflect.getMetadata(KEY_JSON_PROP, target, targetKey);
}
exports.getJsonProperty = getJsonProperty;

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
/** Built-in value references. */
const propertyIsEnumerable = Object.prototype.propertyIsEnumerable;
/* Built-in method references for those with the same name as other `lodash` methods. */
const nativeGetSymbols = Object.getOwnPropertySymbols;
/**
 * Creates an array of the own enumerable symbols of `object`.
 *
 * @private
 * @param {Object} object The object to query.
 * @returns {Array} Returns the array of symbols.
 */
function getSymbols(object) {
  if (object == null) {
    return [];
  }
  object = Object(object);
  return nativeGetSymbols(object).filter(symbol => propertyIsEnumerable.call(object, symbol));
}
exports.default = getSymbols;
"use strict";

// @ts-nocheck
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isBuffer = void 0;
/**
 * Checks if `value` is a buffer.
 *
 * @since 5.4.0
 * @category Lang
 * @param value The value to check.
 * @returns Returns `true` if `value` is a buffer, else `false`.
 * @example
 *
 * ```js
 * isBuffer(new Buffer(2))
 * // => true
 *
 * isBuffer(new Uint8Array(2))
 * // => false
 * ```
 */
function isBuffer(value) {
  var _a;
  // try to use nodejs native isBuffer
  if (typeof Buffer == "function") {
    const nativeIsBuffer = Buffer === null || Buffer === void 0 ? void 0 : Buffer.isBuffer;
    if (nativeIsBuffer) {
      return nativeIsBuffer(value);
    }
  }
  const className = (_a = value === null || value === void 0 ? void 0 : value.constructor) === null || _a === void 0 ? void 0 : _a.name;
  return className == "Buffer";
}
exports.isBuffer = isBuffer;
exports.default = isBuffer;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.join = void 0;
/**
 * @ignore
 */
const nativeJoin = Array.prototype.join;
/**
  * Converts all elements in `array` into a string separated by `separator`.
  *
  * @since 5.0.0
  * @category Array
  * @param array The array to convert.
  * @param separator The element separator.
  * @returns Returns the joined string.
  * @example
  *
  * ```js
  * join(['a', 'b', 'c'], '~');
  * // => 'a~b~c'
  * ```
  */
function join(array, separator = ",") {
  return array == null ? "" : nativeJoin.call(array, separator);
}
exports.join = join;
exports.default = join;
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { QuickSearchService } from './services/quick-search.service';
import { SiteService } from './services/site.service';
import { EquipmentsService } from './services/equipments.service';
import { ReturnOrderService } from './services/return-order.service';
import { StockOrderService } from './services/stock-order.service';
import { ServiceOrderService } from './services/service-order.service';
import { GroupsService } from './services/groups.service';
import { CustomOrderService } from './services/custom-order.service';
import { AccountService } from './services/account.service';
import { AttachmentsService } from './services/attachments.service';
import { CustomersService } from './services/customers.service';
import { DeliveryPlannerService } from './services/delivery-planner.service';
import { JobsService } from './services/jobs.service';
import { OperationService } from './services/operation.service';
import { OrderService } from './services/order.service';
import { PermitPlanStatusService } from './services/permit-plan-status.service';
import { ProjectPlannerService } from './services/project-planner.service';
import { ResourcesService } from './services/resources.service';
import { ServicePeriodsService } from './services/service-periods.service';
import { TitlesService } from './services/titles.service';
import { UsersService } from './services/users.service';
import { WarehouseService } from './services/warehouse.service';
import { SelectorService } from './services/selector.service';
import { QuoteService } from './services/quote.service';

@NgModule({
  declarations: [],
  imports: [CommonModule], 
  providers: [provideHttpClient(withInterceptorsFromDi())]
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        AccountService,
        AttachmentsService,
        CustomOrderService,
        CustomersService,
        DeliveryPlannerService,
        EquipmentsService,
        GroupsService,
        JobsService,
        OperationService,
        OrderService,
        QuoteService,
        PermitPlanStatusService,
        ProjectPlannerService,
        QuickSearchService,
        ResourcesService,
        ReturnOrderService,
        ServiceOrderService,
        ServicePeriodsService,
        SiteService,
        StockOrderService,
        TitlesService,
        UsersService,
        WarehouseService,
        SelectorService
      ]
    };
  }
 }

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ServiceMetadata = void 0;
const LRUMap_1 = require("@newdash/newdash/functional/LRUMap");
const edm_1 = require("../edm");
const defineEntities_1 = require("./defineEntities");
const JsonCreator_1 = require("./JsonCreator");
const xmlMetadata_1 = require("./xmlMetadata");
class ServiceMetadata {
  constructor(edmx, options) {
    this.edmx = edmx;
    this.options = options;
    this._cache = new LRUMap_1.LRUMap();
  }
  static loadFromJson(json, options) {
    return ServiceMetadata.processMetadataJson(json, options);
  }
  static processMetadataJson(json, options) {
    const edmx = new edm_1.Edm.Edmx(json);
    return new this(edmx, options);
  }
  static processEdmx(edmx, options) {
    return new this(edmx, options);
  }
  static defineEntities(entityConfig, options) {
    const json = (0, defineEntities_1.defineEntities)(entityConfig);
    const edmx = new edm_1.Edm.Edmx(json);
    return new this(edmx, options);
  }
  _tryGetCache(key, producer) {
    if (!this._cache.has(key)) {
      this._cache.set(key, producer());
    }
    return this._cache.get(key);
  }
  getAllEntityTypes() {
    return this._tryGetCache('_all_models_', () => {
      const rt = [];
      this.edmx.dataServices.schemas.forEach(schema => {
        schema.entityTypes.forEach(entityType => {
          rt.push(entityType);
        });
      });
      return rt;
    });
  }
  getEntityTypeByName(modelName) {
    return this._tryGetCache(`_model_${modelName}_`, () => {
      for (const model of this.getAllEntityTypes()) {
        if (model.name === modelName) {
          return model;
        }
        return null;
      }
    });
  }
  getAllEntitySets() {
    return this._tryGetCache('_all_models_', () => {
      const rt = [];
      this.edmx.dataServices.schemas.forEach(schema => {
        schema.entityContainer.forEach(container => {
          container.entitySets.forEach(entitySet => {
            rt.push(entitySet);
          });
        });
      });
      return rt;
    });
  }
  getEntitySetByName(entitySetName) {
    return this._tryGetCache(`_es_${entitySetName}_`, () => {
      for (const es of this.getAllEntitySets()) {
        if (es.name === entitySetName) {
          return es;
        }
        return null;
      }
    });
  }
  document(format = 'xml') {
    switch (format) {
      case 'json':
      case 'application/json':
        return (0, JsonCreator_1.jsonStringify)(this.edmx);
      case 'xml':
      case 'application/xml':
        return this.process(this.edmx, this.options);
      default:
        throw Error(`not support document type for ${format}`);
    }
  }
  process(edmx, options) {
    const xmlMetadata = new xmlMetadata_1.XmlMetadata(options, edmx);
    return xmlMetadata.processMetadata();
  }
  /**
   * create metadata handler for express
   *
   * @param format
   */
  requestHandler(format) {
    return (req, res, __) => {
      switch (req.get('Accept')) {
        case 'application/json':
        case 'json':
          res.set('Content-Type', 'application/json');
          res.send(this.document('application/json'));
          break;
        default:
          res.set('Content-Type', 'application/xml');
          res.send(this.document('application/xml'));
          break;
      }
    };
  }
}
exports.ServiceMetadata = ServiceMetadata;

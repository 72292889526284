"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isObjectLike = void 0;
/**
 * Checks if `value` is object-like. A value is object-like if it's not `null`
 * and has a `typeof` result of "object".
 *
 * @since 5.3.0
 * @category Lang
 * @param value The value to check.
 * @returns {boolean} Returns `true` if `value` is object-like, else `false`.
 * @example
 *
 * ```js
 * isObjectLike({})
 * // => true
 *
 * isObjectLike([1, 2, 3])
 * // => true
 *
 * isObjectLike(Function)
 * // => false
 *
 * isObjectLike(null)
 * // => false
 * ```
 */
function isObjectLike(value) {
  return value != null && typeof value == "object";
}
exports.isObjectLike = isObjectLike;
exports.default = isObjectLike;
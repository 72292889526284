"use strict";

// ref: https://stackoverflow.com/a/46432113/4380476
// Author: odinho - Velmont
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LRUMap = void 0;
const assert_1 = require("../assert");
const errors_1 = require("../assert/errors");
/**
 * LRU (Least Recently Used) Map implementation
 */
class LRUMap extends Map {
  /**
   * LRU (Least Recently Used) Map implementation
   *
   * will remove the oldest item when reach the size limit
   *
   * @category Functional
   * @since 5.15.0
   * @param maxSize maximum cache item number, default is 1024
   * @example
   *
   * ```ts
   * const m = new LRUMap(1)
   * m.set('a','v') // {'a':'v'}
   * m.set('b','c') // {'b':'c'}
   * ```
   */
  constructor(maxSize = 1024) {
    super();
    (0, assert_1.mustProvide)(maxSize, "maxSize", "number");
    if (maxSize < 1) {
      throw new errors_1.InValidParameterError(`for LRUMap, must provide a positive value which >=1, giving ${maxSize}`);
    }
    this._maxSize = maxSize;
  }
  get(key) {
    if (super.has(key)) {
      const item = super.get(key);
      // refresh key
      super.delete(key);
      super.set(key, item);
    }
    return super.get(key);
  }
  set(key, val) {
    // refresh key
    if (super.has(key)) {
      super.delete(key);
    }
    // evict oldest
    else if (this.size >= this._maxSize) {
      super.delete(this.first());
    }
    super.set(key, val);
    return this;
  }
  clear() {
    super.clear();
  }
  first() {
    return super.keys().next().value;
  }
  setMaxSize(maxSize) {
    this._maxSize = maxSize;
  }
  getMaxSize() {
    return this._maxSize;
  }
}
exports.LRUMap = LRUMap;
exports.default = LRUMap;
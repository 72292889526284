"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.findAll = exports.findOne = exports.isType = exports.required = exports.equals = exports.is = exports.stringify = void 0;
const map_1 = require("@newdash/newdash/map");
const visitor_1 = require("./visitor");
function stringify(value, index, next) {
  return (0, map_1.map)(value.slice(index, next), ch => String.fromCharCode(ch)).join('');
}
exports.stringify = stringify;
function is(value, compare) {
  for (let i = 0; i < compare.length; i++) {
    if (value === compare.charCodeAt(i)) {
      return true;
    }
  }
  return false;
}
exports.is = is;
function equals(value, index, compare) {
  let i = 0;
  while (value[index + i] === compare.charCodeAt(i) && i < compare.length) {
    i++;
  }
  return i === compare.length ? i : 0;
}
exports.equals = equals;
function required(value, index, comparer, min, max) {
  let i = 0;
  max = max || value.length - index;
  while (i < max && comparer(value[index + i])) {
    i++;
  }
  return i >= (min || 0) && i <= max ? index + i : 0;
}
exports.required = required;
function isType(node, type) {
  return (node === null || node === void 0 ? void 0 : node.type) == type;
}
exports.isType = isType;
function findOne(node, type) {
  let rt;
  (0, visitor_1.createTraverser)({
    [type]: v => {
      rt = v;
    }
  })(node);
  return rt;
}
exports.findOne = findOne;
/**
 * find all nodes in ast node by type
 *
 * @param node
 * @param type
 */
function findAll(node, type) {
  const rt = [];
  (0, visitor_1.createTraverser)({
    [type]: v => {
      rt.push(v);
    }
  })(node);
  return rt;
}
exports.findAll = findAll;
exports.default = {
  stringify,
  is,
  equals,
  required
};

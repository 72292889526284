"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ServiceDocument = void 0;
const metadata_1 = require("../service/metadata");
const JsonDocument_1 = require("./JsonDocument");
class ServiceDocument extends metadata_1.ServiceMetadata {
  constructor(edmx, options) {
    super(edmx, options);
  }
  document(format = 'json') {
    switch (format) {
      case 'xml':
        throw new Error('Not implemented');
      default:
        const jsonDocument = new JsonDocument_1.JsonDocument(this.options, this.edmx);
        return jsonDocument.processMetadata();
    }
  }
  process(edmx, options) {
    const jsonDocument = new JsonDocument_1.JsonDocument(options, edmx);
    this.data = jsonDocument.processMetadata();
  }
  requestHandler(format) {
    return (req, res, next) => {
      res.set('OData-Version', '4.0');
      const data = this.document(format);
      if (!data['@odata.context']) {
        let url = `${req.protocol}://${req.get('host')}${req.originalUrl.split('?').shift()}`;
        if (url.slice(-1) !== '/') {
          url += '/';
        }
        data['@odata.context'] = `${url}$metadata`;
      }
      res.json(data);
    };
  }
}
exports.ServiceDocument = ServiceDocument;
